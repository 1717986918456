import { ThemeIcons, ThemeType } from "./types";
import { colors } from "./colors";
import { typography } from "./typography";

export const Icons: ThemeIcons = {
    logo: require('@/assets/logo.png'),
    logo_menu: require('@/assets/logo-menu.png'),
    logo_menu_2x: require('@/assets/logo-menu-2x.png'),
    football: require('@/assets/football.png'),
    amex: require('@/assets/amex.png'),
    casino: require('@/assets/casino.png'),
    discover: require('@/assets/discover.png'),
    facebook: require('@/assets/facebook.png'),
    favicon: require('@/assets/favicon.png'),
    instagram: require('@/assets/instagram.png'),
    linkedin: require('@/assets/linkedin.png'),
    mastercard: require('@/assets/mastercard.png'),
    paypal: require('@/assets/paypal.png'),
    twitter: require('@/assets/twitter.png'),
    visa: require('@/assets/visa.png'),
    youtube: require('@/assets/youtube.png'),
    search: require('@/assets/search.png'),
    login: require('@/assets/login.png'),
    expand: require('@/assets/expand.png'),
    eye: require('@/assets/eye.png'),
    secure_payment: require('@/assets/secure-payment.png'),
    secure_payment_white: require('@/assets/secure-payment-white.png'),
    stripe: require('@/assets/stripe.png'),
    cards: require('@/assets/cards.png'),
    info: require('@/assets/info.png'),
    avatar: require('@/assets/avatar.jpg'),
    notification: require('@/assets/notification.png'),
    envelope: require('@/assets/envelope.png'),
    user: require('@/assets/user.png'),
    burger_icon: require('@/assets/burger-menu.png'),
    iphone: require('@/assets/iphone15.jpg'),
    basket: require('@/assets/basket.png'),
    close: require('@/assets/x-square.png'),
    bets: require('@/assets/bets.png'),
    clock: require('@/assets/clock.png'),
    upcoming: require('@/assets/upcoming.png'),
    pending: require('@/assets/pending.png'),
    canceled: require('@/assets/canceled.png'),
    postponed: require('@/assets/postponed.png'),
    win: require('@/assets/win.png'),
    lose: require('@/assets/lose.png'),
    left_arrow: require('@/assets/left.png'),
    right_arrow: require('@/assets/right.png')
}

export const THEME: ThemeType = {
    icons: Icons,
    typography: typography,
    colors: colors
}

