import { View, StyleSheet, ScrollView, Pressable } from "react-native";
import { useFocusEffect, ParamListBase } from "@react-navigation/native"
import { useCallback, useEffect, useState } from "react"
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { typography } from "@/theme/typography";
import { colors } from "@/theme/colors";
import { Button, Divider, Image } from "@rneui/themed";
import { isExtraLarge, isLarge } from "@/util/isLarge";
import TextWhite from "@/components/TextWhite";
import { Icons } from "@/theme/theme";
import MyInformation from "@/components/AccountPage/MyInformation";
import MyPoints from "@/components/AccountPage/MyPoints";
import Footer from "@/components/Footer";
import ScrollViewIndicator from 'react-native-scroll-indicator';
import { User } from "@/theme/types";
import api from "@/util/api";
import { useTranslation } from "react-i18next";
import MyPackages from "@/components/AccountPage/MyPackages";
import useSnackbar from "@/hooks/useSnackbar"
import MyPurchases from "@/components/AccountPage/MyPurchases";

type AccountProps = {
    setIsAccountPage: React.Dispatch<React.SetStateAction<boolean>>
    navigation: DrawerNavigationProp<ParamListBase>
    route: any
}


const Account: React.FC<AccountProps> = ({ setIsAccountPage, navigation, route }) => {

    const { params } = route
    const { accountPage } = params
    const { t } = useTranslation()
    const [name, setName] = useState<string>(accountPage)
    const [SnackbarComponent, showSnackbar] = useSnackbar()

    useEffect(() => {

        setName(accountPage)

    }, [accountPage])

    const state = navigation.getState()

    const handleClick = (param: string) => {
        navigation.setParams({ accountPage: param })
    }

    useFocusEffect(
        useCallback(() => {
            setIsAccountPage(true)

            return () => setIsAccountPage(false)
        }, [navigation])
    )


    return (
        name &&
        <>

            <ScrollViewIndicator
                shouldIndicatorHide={false}
                flexibleIndicator={false}
                scrollIndicatorStyle={{ backgroundColor: colors.orange }}
                scrollIndicatorContainerStyle={{ backgroundColor: colors.bg_secondary }}
            >
                <ScrollView >
                    <View style={styles.pageContainer}>


                        <View style={{ flex: 1, flexDirection: isLarge ? 'row' : 'column', gap: 20 }}>

                            <View style={[{ backgroundColor: isLarge && colors.white_transparent, padding: 20, borderRadius: 15, height: isLarge ? 600 : 200, elevation: 5 }, isLarge && { flex: 3 }]}>
                                <TextWhite style={{ fontSize: typography.p, fontWeight: '600', textAlign: 'center', marginBottom: 20 }}>{t('account.control-panel')}</TextWhite>
                                <Divider color="white" />

                                <ScrollView horizontal={isLarge ? false : true} contentContainerStyle={{ flex: 1, marginVertical: isLarge ? 20 : 5, gap: 30 }}>
                                    <Pressable onPress={() => handleClick('myinformation')} style={styles.menuItemContainer}>
                                        <TextWhite
                                            style={[styles.text, { color: name === 'myinformation' ? colors.orange : colors.text }]}
                                        >
                                            {t('account.my-information')}
                                        </TextWhite>
                                        {isLarge && <Image style={styles.icon} source={Icons.expand} />}
                                    </Pressable>
                                    {/* <Pressable style={styles.menuItemContainer}>
                                    <TextWhite style={styles.text} >Οι ρυθμίσεις μου</TextWhite>
                                    {isLarge && <Image style={styles.icon} source={Icons.expand} />}
                                </Pressable> */}
                                    <Pressable onPress={() => handleClick('mypackages')} style={styles.menuItemContainer}>
                                        <TextWhite style={[styles.text, { color: name === 'mypackages' ? colors.orange : colors.text }]} > {t('account.my-packages')}</TextWhite>
                                        {isLarge && <Image style={styles.icon} source={Icons.expand} />}
                                    </Pressable>
                                    <Pressable onPress={() => handleClick('mypoints')} style={styles.menuItemContainer}>
                                        <TextWhite style={[styles.text, { color: name === 'mypoints' ? colors.orange : colors.text }]} >{t('account.my-points')}</TextWhite>
                                        {isLarge && <Image style={styles.icon} source={Icons.expand} />}
                                    </Pressable>
                                    <Pressable onPress={() => handleClick('mypurchases')} style={styles.menuItemContainer}>
                                        <TextWhite style={[styles.text, { color: name === 'mypurchases' ? colors.orange : colors.text }]} >{t('account.my-purchases')}</TextWhite>
                                        {isLarge && <Image style={styles.icon} source={Icons.expand} />}
                                    </Pressable>
                                </ScrollView>
                            </View>

                            {name === 'myinformation' && <MyInformation SnackbarComponent={SnackbarComponent} showSnackbar={showSnackbar} />}
                            {name === 'mypoints' && <MyPoints />}
                            {name === 'mypackages' && <MyPackages SnackbarComponent={SnackbarComponent} showSnackbar={showSnackbar} />}
                            {name === 'mypurchases' && <MyPurchases />}


                        </View>
                    </View>
                    <Footer />
                </ScrollView>
            </ScrollViewIndicator>
        </>
    )
}

const styles = StyleSheet.create({
    pageContainer: { paddingHorizontal: isExtraLarge ? 140 : 10 },
    menuContaienr: { backgroundColor: 'red' },
    icon: { width: 15, height: 10 },
    menuItemContainer: { flexDirection: 'row', gap: 20, alignItems: 'center', justifyContent: 'flex-end' },
    text: {
        fontSize: typography.menu,
        fontWeight: '600',
        borderRadius: 15,
        paddingVertical: !isLarge ? 6 : 0,
        paddingHorizontal: !isLarge ? 10 : 0,
        backgroundColor: !isLarge ? '#ffffff1a' : 'transparent'
    }
})

export default Account