import React, { useCallback, useState } from "react";
import { ScrollView, StyleSheet, View, Pressable } from "react-native";
import { Text, Card, Button, Input } from "@rneui/themed";
import { useAuthContext } from "@/context/AuthProvider";
import { useFocusEffect, useNavigation } from "@react-navigation/native"
import api from "@/util/api";
import { isLarge } from "@/util/isLarge";
import { Icons } from "@/theme/theme";
import { colors } from "@/theme/colors";
import { Image } from "@rneui/themed";
import TextWhite from "@/components/TextWhite";
import { typography } from "@/theme/typography";
import ScrollViewIndicator from 'react-native-scroll-indicator';
import { useTranslation } from "react-i18next";

import * as yup from 'yup'
import { Formik, FormikValues } from 'formik';
import FieldInput from "@/components/FieldInput";

const LoginSchema = yup.object({
  email: yup.
    string().
    required().
    email(),
  first_name: yup.
    string().
    required(),
  last_name: yup.
    string().
    required(),
  password: yup.
    string().
    required().
    min(6),
  password_confirmation: yup.
    string().
    required().
    min(6).
    oneOf([yup.ref("password")], "Passwords must match"),
})


export default function Register({ route, setIsLoginPage }) {
  const navigation = useNavigation();
  const { register, error, device, setError, setRegisterPayload } = useAuthContext();
  const { params } = route
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const [errorRegister, setErrorRegister] = useState<string>()

  const validateRegister = async (values: FormikValues) => {
    setLoading(true)

    try {
      const res = await api().post('/validateRegister', {
        email: values.email,
        password: values.password,
        password_confirmation: values.password_confirmation,
        first_name: values.first_name,
        last_name: values.last_name,
        referral_code: values.referral_code,
        price: params.price,
        productId: params.productId
      })
      if (res?.status >= 200 && res?.status <= 300) {
        setRegisterPayload(
          {
            email: values.email,
            password: values.password,
            password_confirmation: values.password_confirmation,
            first_name: values.first_name,
            last_name: values.last_name,
            referral_code: values.referral_code,
            price: params.price,
            productId: params.productId,
            name: params?.name,
            paymentType: params?.paymentType,
            monthly: params?.monthly ,
            sportName : params?.sportName
          }
        )
        navigation.navigate('Payment')
      }


    } catch (e: any) {
      setErrorRegister(e?.errors?.[0])
    } finally {
      setLoading(false)
    }




    // api()
    //   .post(URL, {
    //     email: values.email,
    //     password: values.password,
    //     password_confirmation: values.passwordConfirmation,
    //     first_name: values.firstName,
    //     last_name: values.lastName,
    //     referral_code: values.referralCode,
    //     device: device
    //   })
    //   //test data to send through navigator https://reactnavigation.org/docs/navigation-prop/
    //   .then((response) => {
    //     navigation.navigate("Payment");
    //   })
    //   //display validation errors
    //   .catch(({ errors }) => {
    //     setError(errors)
    //   });



  };

  useFocusEffect(
    useCallback(() => {
      setIsLoginPage(true)

      return () => setIsLoginPage(false)
    }, [navigation])
  )


  return (
    <ScrollViewIndicator

      shouldIndicatorHide={false}
      flexibleIndicator={false}
      scrollIndicatorStyle={{ backgroundColor: colors.orange }}
      scrollIndicatorContainerStyle={{ backgroundColor: colors.bg_secondary }}
    >
      <View style={styles.container}>
        <Image onPress={() => navigation.navigate('Home')} style={{ width: 320, height: 70 }} source={Icons.logo} />
        <Card containerStyle={styles.card}>

          {errorRegister && <Text style={styles.errorText}>{errorRegister}</Text>}

          <TextWhite style={styles.enter}>
            {t("registerPage.create-account")}
          </TextWhite>

          <View style={{ flexDirection: 'row', gap: 10, alignItems: 'center', marginVertical: 40 }}>
            <TextWhite style={styles.member}>
              {t("registerPage.already-member")}
            </TextWhite>
            <Pressable onPress={() => navigation.navigate('Login')}>
              <Text style={{ color: colors.orange, fontSize: typography.p }} >{t("login")}</Text>
            </Pressable>
          </View>

          <Formik
            initialValues={{
              email: '',
              password: '',
              password_confirmation: '',
              first_name: '',
              last_name: '',
              referral_code: ' '
            }}
            validationSchema={LoginSchema}
            onSubmit={(values, action) => validateRegister(values)}
          >
            {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (

              <View style={{ gap: 20 }}>

                <FieldInput
                  onChangeText={handleChange('email')}
                  onBlur={handleBlur('email')}
                  value={values.email}
                  placeholder="Email"
                />

                {errors.email && touched.email && <Text style={styles.errorText}>*{errors.email}</Text>}
                {errors.username && touched.username && <Text style={styles.errorText}>*{errors.username}</Text>}

                <View style={{ flexDirection: 'row', gap: 20 }}>
                  <FieldInput
                    onChangeText={handleChange('first_name')}
                    onBlur={handleBlur('first_name')}
                    value={values.first_name}
                    placeholder={t("form.firstname")}
                  />

                  <FieldInput
                    onChangeText={handleChange('last_name')}
                    onBlur={handleBlur('last_name')}
                    value={values.last_name}
                    placeholder={t("form.lastname")}
                  />

                </View>
                {errors.first_name && touched.first_name && <Text style={styles.errorText}>*{errors.first_name}</Text>}
                {errors.last_name && touched.last_name && <Text style={styles.errorText}>*{errors.last_name}</Text>}
                <FieldInput
                  onChangeText={handleChange('password')}
                  onBlur={handleBlur('password')}
                  value={values.password}
                  placeholder={t("form.password")}
                  password
                />
                {errors.password && touched.password && <Text style={styles.errorText}>*{errors.password}</Text>}

                <FieldInput
                  onChangeText={handleChange('password_confirmation')}
                  onBlur={handleBlur('password_confirmation')}
                  value={values.password_confirmation}
                  placeholder={t("form.confirm-password")}
                  password
                />
                {errors.password_confirmation && touched.password_confirmation && <Text style={styles.errorText}>*{errors.password_confirmation}</Text>}

                <FieldInput
                  onChangeText={handleChange('referral_code')}
                  onBlur={handleBlur('referral_code')}
                  value={values.referral_code}
                  placeholder="User Referral code"

                />
                {errors.referral_code && touched.referral_code && <Text style={styles.errorText}>*{errors.referral_code}</Text>}
                <Button
                  containerStyle={{ marginTop: 20, alignItems: 'flex-end' }}
                  buttonStyle={{ borderWidth: 1, borderColor: colors.orange, borderRadius: 10, width: 120, backgroundColor: colors.orange }}
                  titleStyle={{ fontSize: typography.normal }}
                  onPress={handleSubmit}
                  title={t("form.next")}
                  disabledStyle={{ backgroundColor: colors.orange }}
                  loadingProp={{ color: colors.orange }}
                  loading={loading}
                />
              </View>
            )}
          </Formik>
        </Card>
      </View>
    </ScrollViewIndicator>

  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    backgroundColor: 'transparent',
    width: '100%',
    height: '100%',
    gap: isLarge ? 100 : 30,
    marginVertical: isLarge ? 100 : 50,
    paddingHorizontal: 10
  },
  card: {
    backgroundColor: colors.white_transparent,
    borderWidth: 0,
    borderRadius: 15,
    width: '100%',
    maxWidth: 568,
    paddingVertical: 30,

  },
  enter: {
    textAlign: "center",
    padding: 12,
    fontSize: typography.h3
  },

  member: {

    fontSize: typography.p
  },
  email: {
    padding: 8,
  },
  firstName: {
    padding: 8,
  },
  lastName: {
    padding: 8,
  },
  password: {
    padding: 8,
  },

  image: {
    marginBottom: 40,
  },
  errorText: {
    color: "#8b0000", // red color
    backgroundColor: "#ffc0cb", // misty rose background color
    padding: 10,
    marginBottom: 5,
    borderRadius: 8,
  },
});
