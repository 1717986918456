import Footer from "@/components/Footer"
import TextWhite from "@/components/TextWhite"
import { CartItemType, decreaseItemQuantity, increaseItemQuantity } from "@/store/cart-actions"
import { uiActions } from "@/store/ui-slice"
import { colors } from "@/theme/colors"
import { typography } from "@/theme/typography"
import { isLarge } from "@/util/isLarge"
import { DrawerNavigationProp } from "@react-navigation/drawer"
import { ParamListBase } from "@react-navigation/native"
import { Button, Divider } from "@rneui/themed"
import { Fragment } from "react"
import { ScrollView, StyleSheet, Text, View, Dimensions, Image } from "react-native"
import ScrollViewIndicator from 'react-native-scroll-indicator'
import { useDispatch, useSelector } from "react-redux"

const { height } = Dimensions.get('window')

type CartScreenProps = {
    navigation: DrawerNavigationProp<ParamListBase>
}

const CartScreen: React.FC<CartScreenProps> = ({ navigation }) => {

    const dispatch = useDispatch()
    const totalPrice = useSelector(state => state.cart.totalPrice)
    const cart: CartItemType[] = useSelector(state => state.cart.items)

    const addItem = (productIndex: number, id: number) => {
        dispatch(increaseItemQuantity(productIndex, id))
    }

    const removeItem = (productIndex: number, id: number) => {
        dispatch(decreaseItemQuantity(productIndex, id))

    }

    const handleCheckoutButton = () => {
        dispatch(uiActions.toggle())
        navigation.navigate('Checkout')
    }

    return (
        <ScrollView>
            <ScrollViewIndicator
                shouldIndicatorHide={false}
                flexibleIndicator={false}
                scrollIndicatorStyle={{ backgroundColor: colors.orange }}
                scrollIndicatorContainerStyle={{ backgroundColor: colors.bg_secondary }}
                style={styles.container}
            >

                <View style={styles.notifContainer}>
                    {cart && cart.map((item: CartItemType, ind: number) => (

                        <Fragment key={item.id}>
                            <Image style={{ width: 80, height: 80, borderRadius: 5 }} source={{ uri: item.image }} />
                            <View style={styles.itemContainer}>
                                <Text style={styles.text}>{item.name}</Text>
                                <Text style={styles.italic}>{item.price}pts</Text>
                               
                            </View>
                            {ind !== cart.length - 1 && <Divider color={colors.orange} />}
                        </Fragment>
                    ))}
                </View>
                <View style={styles.checkoutContainer}>
                    
                    <Button
                        onPress={handleCheckoutButton}
                        buttonStyle={{ borderColor: colors.orange, borderRadius: 8 }}
                        titleStyle={{ color: colors.orange }}
                        type='outline'
                        title='Checkout'
                    />
                </View>
            </ScrollViewIndicator>
            <Footer />
        </ScrollView>
    )
}


const styles = StyleSheet.create({
    buttonsContainer: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    modal: {
        alignItems: 'flex-end',
        top: 40,
        elevation: 5,
        justifyContent: 'flex-start'
    },
    container: {
        flex: 1,
        width: isLarge ? 500 : '100%',
        maxHeight: height / 1.5
    },
    notifContainer: {
        backgroundColor: colors.bg_main,
        padding: 10,

    },
    itemContainer: {
        gap: 10,
        marginVertical: 15,
        padding: 10,
        borderRadius: 6
    },
    text: {
        color: colors.text,
        fontSize: 18
    },
    price: {
        color: colors.text,
        fontSize: typography.normal
    },
    italic: {
        color: '#76899d',
        fontStyle: 'italic',
        fontSize: 12
    },
    checkoutContainer: {
        gap: 10,
        marginVertical: 15,
        padding: 10,
        borderRadius: 6,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },

})


export default CartScreen