import { useState } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { NavigationProp } from '@react-navigation/native';
import { colors } from '@/theme/colors';
import { Button, Chip, Icon, Image } from '@rneui/themed';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useAuthContext } from '@/context/AuthProvider';
import TextWhite from './TextWhite';
import { typography } from '@/theme/typography';
import { Icons } from '@/theme/theme';
import { isLarge } from '@/util/isLarge';
import api from '@/util/api';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { uiActions } from '@/store/ui-slice';

type ParamList = {
    Home: undefined;
};

type StripeCheckoutFormType = {
    navigation: NavigationProp<ParamList>
    clientSecret: string
    price: number
    productId: string | number
    name: string
    paymentType: string | undefined
    monthly: boolean
    sportName: string
}

const StripeCheckoutFrom: React.FC<StripeCheckoutFormType> = ({ navigation, clientSecret, price, productId, name, paymentType = null, monthly, sportName }) => {

    const dispatch = useDispatch()
    const { t } = useTranslation()
    const stripe = useStripe()
    const elements = useElements()
    const [message, setMessage] = useState(null)
    const [isProcessing, setIsProcessing] = useState(false)

    const { user, loggedIn, registerPayload, setRegisterPayload, device, logIn, setUser } = useAuthContext()

    const handleError = (error: any) => {
        setIsProcessing(false)
        setMessage(error.message)
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault()
        setIsProcessing(true)


        if (!stripe || !elements) {
            return
        }

        const cardNumber = elements?.getElement('cardNumber')
        const cardExpiry = elements?.getElement('cardExpiry')
        const cardCvc = elements?.getElement('cardCvc')

        if (!cardNumber || !cardExpiry || !cardCvc) {
            console.error('Card Information not found')
            return
        }

        const { setupIntent, error: CardError } = await stripe.confirmCardSetup(clientSecret, {
            payment_method: {
                card: cardNumber,
            },
        })

        console.log('setup intent', setupIntent)

        if (CardError) {
            handleError(CardError)
            return
        }

        if (loggedIn) {

            try {
                const res = await api().post('/user/subscribe', {
                    price: price,
                    productId: productId,
                    paymentMethod: setupIntent?.payment_method
                })

                console.log('subscribe money', res);

                if (res.status >= 200 && res.status <= 300) {
                    dispatch(uiActions.showNotification({
                        message: res?.data?.message,
                        visible: true

                    }))
                    navigation.navigate('Home')
                }
            } catch (error) {
                handleError(error)
            } finally {
                setIsProcessing(false)
            }

        } else {

            const URL = device === 'web' ? '/register' : '/auth/token/register'

            try {

                const res = await api().post(URL, {
                    ...registerPayload,
                    paymentMethod: setupIntent?.payment_method
                })

                console.log('subscribe money', res)

                if (res.status >= 200 && res.status <= 300) {
                    cardNumber.clear()
                    cardExpiry.clear()
                    cardCvc.clear()
                    logIn(registerPayload?.email, registerPayload?.password, () => navigation.navigate('Home'))
                    dispatch(uiActions.showNotification({
                        message: res?.data?.message,
                        visible: true

                    }))
                    navigation.navigate('Home')
                }

            } catch (e) {
                dispatch(uiActions.showNotification({
                    message: 'Something went wrong !',
                    visible: true

                }))

                handleError(e)
            } finally {
                setIsProcessing(false)
            }
        }


    }

    const handleSubmitPoints = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setIsProcessing(true)
        const existingItem = user
        if (loggedIn) {
            try {
                const res = await api().post('/user/subscribe', {
                    price: price,
                    productId: productId,
                    paymentType: paymentType
                })

                console.log('ers', res);

                setUser({ ...existingItem, points: existingItem.points - price })
                dispatch(uiActions.showNotification({
                    message: 'Payment was successful !',
                    visible: true

                }))
                navigation.navigate('Home')
            } catch (error) {
                dispatch(uiActions.showNotification({
                    message: 'Something went wrong !',
                    visible: true

                }))
                handleError(e?.errors)
            } finally {
                setIsProcessing(false)
            }
        } else {
            const URL = device === 'web' ? '/register' : '/auth/token/register'
            console.log('register payload , Type:', registerPayload, paymentType)
            try {
                const res = await api().post(URL, {
                    ...registerPayload,
                    paymentType: paymentType
                })
                console.log('register handle submit point ', res)
                if (res?.status >= 200 && res?.status <= 300) {
                    dispatch(uiActions.showNotification({
                        message: res?.data?.message,
                        visible: true

                    }))
                    navigation.navigate('Home')
                }
            } catch (e) {
                dispatch(uiActions.showNotification({
                    message: 'Something went wrong !',
                    visible: true

                }))
                handleError(e?.errors)
            } finally {
                setIsProcessing(false)
            }
        }
    }

    return (
        <>
            <View style={styles.container}>
                <TextWhite style={styles.name}>{name} {sportName}</TextWhite>
                <TextWhite style={styles.price}>{price} {paymentType === 'points' ? 'pts' : '€'} {monthly ? t('per-month') : t('per-year')}</TextWhite>
                {!paymentType &&
                    <>
                        <View>
                            <View style={{ flexDirection: 'row', gap: 20, alignItems: 'center' }}>
                                <Image source={Icons.secure_payment} style={{ width: 40, height: 33 }} />
                                <TextWhite style={{ fontSize: typography.h4, fontWeight: 'bold' }}>{t('payment.safepay')}</TextWhite>
                            </View>
                            <TextWhite >{t('payment.subtitle')}</TextWhite>
                        </View>

                        <View style={{ flexDirection: 'row', gap: 10, borderWidth: 1, borderColor: 'white', borderRadius: 15, padding: 11, alignItems: 'center' }}>
                            <Image source={Icons.secure_payment_white} style={{ width: 21.85, height: 19.74 }} />
                            <TextWhite style={{ fontSize: typography.small }}>{t('payment.stripe')}</TextWhite>
                        </View>

                        <View style={{ borderWidth: 1, borderColor: 'white', borderRadius: 15, padding: 11, flexDirection: 'row', gap: isLarge ? 20 : 5, alignItems: 'center', flexWrap: 'wrap', marginVertical: 30 }}>
                            <Image style={{ width: 93, height: 39 }} source={Icons.stripe} />
                            <Image style={{ width: 235, height: 21 }} source={Icons.cards} />
                        </View>

                        <View style={styles.containerField}>
                            <TextWhite style={styles.fieldName}>{t('payment.cardnumber')}</TextWhite>
                            <View style={styles.cardElement}>
                                <CardNumberElement
                                    options={styleOptions}
                                />
                            </View>
                        </View>

                        <View style={{ flexDirection: 'row', width: '100%', gap: 20 }}>
                            <View style={styles.containerField}>
                                <TextWhite style={styles.fieldName}>{t('payment.expiry')}</TextWhite>
                                <View style={styles.cardElement}>
                                    <CardExpiryElement options={styleOptions}
                                    />
                                </View>
                            </View>

                            <View style={styles.containerField}>
                                <TextWhite style={styles.fieldName}>CVC</TextWhite>
                                <View style={styles.cardElement}>
                                    <CardCvcElement options={styleOptions}
                                    />
                                </View>
                            </View>
                        </View>
                    </>
                }

                <Button
                    containerStyle={styles.button}
                    titleStyle={{ fontSize: typography.normal, color: colors.text }}
                    type='clear'
                    onPress={paymentType === null ? handleSubmit : handleSubmitPoints}
                    disabled={isProcessing}
                    loading={isProcessing}
                    loadingProps={{ color: colors.bg_main }}
                    title={t('payment.button')}
                />

                {message &&
                    <Chip
                        onPress={() => setMessage(null)}
                        buttonStyle={{ backgroundColor: '#653434', borderWidth: 0 }}
                        titleStyle={{ color: 'white' }}
                        title={message}
                    />
                }

            </View >
        </>
    )
}

const styles = StyleSheet.create({
    container: { backgroundColor: colors.white_transparent, borderRadius: 15, maxWidth: 600, width: '100%', padding: 40, gap: 20, marginVertical: 40 },
    containerField: { gap: 10, flexGrow: 1 },
    cardElement: { backgroundColor: '#FFFFFF26', borderRadius: 15, padding: 15 },
    fieldName: { fontSize: typography.small, fontWeight: '200', marginLeft: 10 },
    button: { backgroundColor: colors.orange, borderRadius: 23, marginTop: 20 },
    name: {
        fontSize: 30,
        fontWeight: 'bold',
        textAlign: 'center'
    },
    price: {
        fontSize: 26,
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: 20
    }
})

const styleOptions = {
    style: {
        base: {
            color: colors.text
        }
    }
}


export default StripeCheckoutFrom