import { typography } from '@/theme/typography'
import { Image } from '@rneui/themed'
import { View, StyleSheet, Text } from 'react-native'
import { LinearGradient } from 'expo-linear-gradient';
import { isLarge } from '@/util/isLarge';

const SectionText = () => {

    return (
        <View style={styles.container}>
            <LinearGradient style={styles.linear} locations={[0.35, 1]} colors={['#F15A2400', '#F15A24']}>
                <Image style={styles.image} source={require('@/assets/male.png')} />
            </LinearGradient>
            <View style={{ flexDirection: 'column', gap: 20, flexShrink: 1, justifyContent: 'space-between' }}>
                {/*<Text style={{ color: 'white', fontSize: typography.h4, fontWeight: '500' }}>THE EXPERTS</Text>*/}
                <Text style={styles.title}>Unlock Expert Guidance</Text>
                <Text ellipsizeMode='tail' style={styles.text} >
                    In the vast $91.43 billion sports betting market, millions of players engage daily, yet only a mere 5% emerge profitable. The key differentiator between winners and losers lies in accessing accurate information regarding bookmakers' odds. Achieving this demands extensive statistical analysis coupled with expert insights.
                </Text>
                <Text ellipsizeMode='tail' style={styles.text} >
                    But, consider this: What if someone could handle all of this for you?
                </Text>
                <Text ellipsizeMode='tail' style={styles.text}>
                    That's precisely what our website and team of experts offer. We do the heavy lifting on your behalf, providing meticulously researched bets with the highest profitability and win percentages, ensuring your success at the end of the season.
                </Text>

            </View>
        </View >
    )

}

const styles = StyleSheet.create({
    container: {
        marginTop: 140,
        marginBottom: 40,
        gap: 40,
        flexDirection: isLarge ? 'row' : 'column',
        paddingHorizontal: 10
    },
    image: {
        width: 184,
        height: 344,

    },
    linear: {
        borderRadius: 20,
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: isLarge ? 0 : 1,
        width: isLarge ? '25%' : '100%'
    },
    title: {
        color: 'white', fontSize: typography.h3, fontWeight: 'bold'
    },
    text: { color: 'white', fontSize: typography.p, fontWeight: '200' }
})

export default SectionText