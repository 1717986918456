import Footer from "@/components/Footer";
import { colors } from "@/theme/colors";
import { isLarge } from "@/util/isLarge";
import { Button, Card, Text } from "@rneui/themed";
import { useState } from "react";
import { StyleSheet, View } from "react-native";
import ScrollViewIndicator from 'react-native-scroll-indicator';
import { useTranslation } from "react-i18next";
import FieldInput from "@/components/FieldInput";
import useSnackbar from "@/hooks/useSnackbar";
import { typography } from "@/theme/typography";
import { Formik, FormikValues } from 'formik';
import * as yup from 'yup';
import TextWhite from "@/components/TextWhite";
import api from "@/util/api";

const LoginSchema = yup.object({
    email: yup.
        string().
        required().
        min(6),

})

const initialValues = {
    email: '',
}

const SendEmail = ({ navigation, route }) => {

    const { params } = route
    const { t } = useTranslation()
    const [SnackbarComponent, showSnackbar] = useSnackbar()
    const [content, setContent] = useState<string>()
    const [loading, setLoading] = useState<boolean>(false)

    const submitForm = async (values: FormikValues, action) => {
        setLoading(true)


        try {
            const res = await api().post('/password/email', values)
            showSnackbar(res?.data?.message)
            action.resetForm()
            // setTimeout(() => {
            //     navigation.navigate('Home')
            // },1000)
            
        } catch (e) {

            action.resetForm()
            showSnackbar(e?.errors)

        } finally {
            setLoading(false)
        }

    }

    return (

        <ScrollViewIndicator

            shouldIndicatorHide={false}
            flexibleIndicator={false}
            scrollIndicatorStyle={{ backgroundColor: colors.orange }}
            scrollIndicatorContainerStyle={{ backgroundColor: colors.bg_secondary }}

        >
            <View style={styleHtml.container}>

                <TextWhite style={styleHtml.enter}>
                    {t("forgot-password-title")}
                </TextWhite>
                <Card containerStyle={styleHtml.card}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={LoginSchema}
                        onSubmit={(values, action) => submitForm(values, action)}
                    >
                        {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
                            <View style={{ gap: 20, justifyContent: 'flex-end' }}>
                                <FieldInput
                                    onChangeText={handleChange('email')}
                                    onBlur={handleBlur('email')}
                                    value={values.email}
                                    placeholder="Email"
                                />

                                {errors.email && touched.email && <Text style={styleHtml.errorText}>*{errors.email}</Text>}

                                <Button
                                    containerStyle={{ alignItems: 'flex-end' }}
                                    buttonStyle={{ borderWidth: 1, borderColor: colors.orange, borderRadius: 10, width: 200, backgroundColor: colors.orange }}
                                    titleStyle={{ fontSize: typography.normal }}
                                    onPress={handleSubmit}
                                    title={t("send-email")}
                                    loading={loading}
                                    disabled={loading}
                                    disabledStyle={{ backgroundColor: colors.orange }}
                                    loadingProp={{ color: colors.orange }}
                                />
                            </View>
                        )}
                    </Formik>
                </Card>
            </View>
            <Footer />
        </ScrollViewIndicator>
    )
}

const styleHtml = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: "center",
        backgroundColor: 'transparent',
        width: '100%',
        height: '100%',
        gap: isLarge ? 100 : 30,
        marginTop: isLarge ? 100 : 50,
        paddingHorizontal: 10
    },
    errorText: {
        color: "#8b0000"
    },

    enter: {
        textAlign: "center",
        fontSize: typography.h3,
        fontWeight: 'bold'
    },

    card: {
        backgroundColor: colors.white_transparent,
        borderWidth: 0,
        borderRadius: 15,
        width: '100%',
        maxWidth: 568,
        paddingVertical: 30,
        marginBottom: '20vh'
    },
})

export default SendEmail