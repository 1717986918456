import { ScrollView, StyleSheet, Text, Image, Pressable, View, Platform, StatusBar } from 'react-native';

import { colors } from '@/theme/colors';
import { Icons } from '@/theme/theme';
import { BetType } from '@/theme/types';
import { ControlledTooltip } from './Table';
import { useTranslation } from 'react-i18next';
import { Badge, Button, PricingCard } from '@rneui/themed';
import { color } from '@rneui/base';
import { typography } from '@/theme/typography';
import { isWeb } from '@/util/isWeb';
import { isLarge } from '@/util/isLarge';
import { BlurView } from 'expo-blur';
import StatusBadge from './StatusBadge';
import Tooltip from 'react-native-walkthrough-tooltip';
import { useMemo, useState } from 'react';
import TextWhite from './TextWhite';

type BetCardsProps = {
    bets: BetType[]
    passedBets: BetType[]
    upcomingBets: BetType[]
}

const BetCards: React.FC<BetCardsProps> = ({ bets, passedBets, upcomingBets }) => {

    const { t } = useTranslation()

    const CustomTooltip = ({ description }: { description: string }) => {
        const [showTip, setTip] = useState(false)
        return (
            <View >
                <Tooltip
                    parentWrapperStyle={{ width: 40 }}
                    contentStyle={{ backgroundColor: colors.orange, minWidth: 200, justifyContent: 'center' }}
                    isVisible={showTip}
                    content={
                        <View style={{ backgroundColor: colors.orange, paddingVertical: 5 }}>
                            <TextWhite>{description}</TextWhite>
                        </View>
                    }
                    onClose={() => setTip(false)}
                    placement="bottom"

                    topAdjustment={Platform.OS === 'android' ? -StatusBar.currentHeight : 0}
                >
                    <Pressable
                        onPress={() => setTip(!showTip)}
                        style={{ alignItems: 'center' }}
                    >
                        <Image source={Icons.info} style={{ width: 20, height: 20 }} />
                    </Pressable>

                </Tooltip>
            </View>
        );
    }

    const reversedUpcomingBets = useMemo(() => {
        return upcomingBets.toReversed()
    }, [upcomingBets])

    const reversedPassedBets = useMemo(() => {
        return passedBets.toReversed()
    }, [passedBets])


    return (
        <ScrollView contentContainerStyle={{ flexDirection: 'column' }}>

            <View style={styles.titleContainer}>
                <Image style={{ width: 26.41, height: 24 }} source={Icons.upcoming} />
                <Text style={styles.title}>Upcoming</Text>
                <Image style={{ width: 57.93, height: 21 }} source={Icons.bets} />
            </View>

            {reversedUpcomingBets?.map((bet: BetType, ind) => {

                let concatBet = ''
                let concatResult = ''
                let concatLeague = ''

                let betType = bet?.betType?.map((type: { value: string }) => {
                    return type.value
                })
                if (betType) {
                    concatBet = betType.join(' ')
                }

                let results = bet?.result?.map((type: { value: string }) => {
                    return type.value
                })
                if (results) {
                    concatResult = results.join(' ')
                }

                let league = bet?.league?.map((type: { value: string }) => {
                    return type.value
                })
                if (league) {
                    concatLeague = league.join(' ')
                }

                return (
                    <View>
                        {bet?.subscriptionType &&
                            <Badge
                                containerStyle={styles.badgeContainer}
                                badgeStyle={styles.badge}
                                textStyle={styles.badgeText}
                                value={bet?.subscriptionType}
                            />
                        }
                        {bet?.status &&
                            <View style={styles.statusContainer}>
                                <StatusBadge
                                    status={bet?.status}
                                />
                            </View>
                        }
                        <PricingCard
                            key={ind}
                            color={colors.orange}
                            containerStyle={styles.container}
                            title={bet?.event || ''}
                            titleStyle={{ fontSize: typography.h4 }}
                            price={`${concatBet || ''} - ${concatResult || ''}`}
                            pricingStyle={{ color: colors.text, fontSize: typography.p }}
                            info={[concatLeague && `${t('table.league')} : ${concatLeague}`, ` ${t('table.odd')} : ${bet?.odds === undefined ? '' : bet?.odds}` || '', ` ${t('table.date')} : ${bet?.event_date} ${bet?.event_time}` || '']}
                            infoStyle={{ color: '#7cd57c' }}
                            button={
                                bet?.description ?
                                    <CustomTooltip description={bet?.description} />
                                    :
                                    <Pressable />
                            }
                        />
                        {/* {bet?.description && <CustomTooltip title={bet?.description} />} */}
                    </View>)
            })}

            <View style={[styles.titleContainer, { marginTop: 50 }]}>
                <Image style={{ width: 24, height: 24 }} source={Icons.clock} />
                <Text style={styles.title}>Recent Bets</Text>
                <Image style={{ width: 57.93, height: 21 }} source={Icons.bets} />
            </View>

            {reversedPassedBets?.map((bet: BetType, ind) => {

                let concatBet = ''
                let concatResult = ''
                let concatLeague = ''

                let betType = bet?.betType?.map((type: { value: string }) => {
                    return type.value
                })
                if (betType) {
                    concatBet = betType.join(' ')
                }

                let results = bet?.result?.map((type: { value: string }) => {
                    return type.value
                })
                if (results) {
                    concatResult = results.join(' ')
                }

                let league = bet?.league?.map((type: { value: string }) => {
                    return type.value
                })
                if (league) {
                    concatLeague = league.join(' ')
                }

                return (
                    <View>
                        {bet?.subscriptionType &&
                            <Badge
                                containerStyle={styles.badgeContainer}
                                badgeStyle={styles.badge}
                                textStyle={styles.badgeText}
                                value={bet?.subscriptionType}
                            />
                        }
                        {bet?.status &&
                            <View style={styles.statusContainer}>
                                <StatusBadge
                                    status={bet?.status}
                                />
                            </View>
                        }
                        <PricingCard
                            key={ind}
                            color={colors.orange}
                            containerStyle={styles.container}
                            title={bet?.event || ''}
                            titleStyle={{ fontSize: typography.h4 }}
                            price={`${concatBet || ''} - ${concatResult || ''}`}
                            pricingStyle={{ color: colors.text, fontSize: typography.p }}
                            info={[concatLeague && `${t('table.league')} : ${concatLeague}`, ` ${t('table.odd')} : ${bet?.odds === undefined ? '' : bet?.odds}` || '', ` ${t('table.date')} : ${bet?.event_date} ${bet?.event_time}` || '']}
                            infoStyle={{ color: '#7cd57c' }}
                            button={
                                bet?.description ?
                                    <CustomTooltip description={bet?.description} />
                                    :
                                    <Pressable />
                            }
                        />
                    </View>)
            })}

        </ScrollView >
    )

}

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'transparent',
        borderWidth: 2,
        borderColor: colors.orange,
        borderRadius: 15,
        elevation: 5,
        shadowColor: '#171717',
        shadowOffset: { width: -2, height: 4 },
        shadowOpacity: 0.2,
        shadowRadius: 3,
    },
    statusContainer: {
        position: 'absolute',
        zIndex: 1,
        right: 30,
        top: 6,
    },
    badge: {
        paddig: 10,
        borderColor: colors.orange,
        backgroundColor: colors.orange
    },
    badgeContainer: {
        position: 'absolute',
        zIndex: 1,
        left: 30,
        top: 6,
    },
    badgeText: {
        padding: 10,
        fontWeight: '700',
        color: colors.bg_main
    },
    title: {
        color: colors.text,
        fontSize: 28,
        fontWeight: '500',
        paddingBottom: 6
    },
    titleContainer: {
        flexDirection: 'row',
        gap: 10,
        alignItems: 'center',
        marginBottom: 20,
        paddingHorizontal: 14
    }
})

export default BetCards