import Animated, { useAnimatedProps, useDerivedValue, useSharedValue, withSpring, withTiming } from "react-native-reanimated"
import { Text, StyleSheet, View } from "react-native"
import { typography } from "@/theme/typography"
import { colors } from "@/theme/colors"
import { Button } from "react-native"
import { useEffect } from "react"
import AnimatedNumber from "react-native-number-animate";

const AnimatedText = ({ value }: { value: string }) => {


    return (
        <AnimatedNumber
            number={value}
            textStyle={styles.text}
            textHeight={25}
            containerStyle={styles.container}
        />
    );
};

const styles = StyleSheet.create({
    text: { color: colors.orange, fontSize: typography.h4, fontWeight: '700', lineHeight: 25 },
    container: {
        height: 25,
        flexDirection: 'row',
        overflow: "hidden"
    }
})

export default AnimatedText