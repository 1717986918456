import api from "@/util/api";

export const fetchData = async (apiEndpoint: string) => {

  try {
    const response = await api().get(apiEndpoint)
  
    return response.data.data.data
  } catch (error) {
    console.error(`Error fetching data from ${apiEndpoint}:`, error);
    throw error
  }

}

export const fetchMenu = () => fetchData("/getMenu");
export const fetchBets = () => fetchData("/bets");
export const fetchImages = () => fetchData("/homepageImages");
