import { colors } from "@/theme/colors"
import { typography } from "@/theme/typography"
import { isLarge } from "@/util/isLarge"
import { isWeb } from "@/util/isWeb"
import { Button, Tab, TabView, Text } from '@rneui/themed'
import { useEffect, useState } from "react"
import { Dimensions, Pressable, ScrollView, StyleSheet, View } from "react-native"
import TextWhite from "../TextWhite"
import { LinearGradient } from "expo-linear-gradient"
import { useNavigation } from "@react-navigation/native"
import { useAuthContext } from "@/context/AuthProvider"
import HTMLView from 'react-native-htmlview';
import ScrollViewIndicator from 'react-native-scroll-indicator';
import Checkbox from 'expo-checkbox';
import { useTranslation } from "react-i18next"
import {
    REACT_APP_FOOTBALL_ID,
    REACT_APP_BASKETBALL_ID,
    REACT_APP_OTHERSPORTS_ID
} from "@env"
import Dropdown from "../Dropdown"

const width = Dimensions.get('window').width

type PackageCardProps = {
    name: string
    description: string | undefined
    price_month: number
    price_year: number
    points_month: number
    points_year: number
    indexMap: number
    length: number
    id: number
    color: boolean | undefined
    enableCategoriesSelection?: boolean
}

const optionsDropdown = [
    { label: 'Basketball', value: 'Basketball' },
    { label: 'Football', value: 'Football' },
    { label: 'Other', value: 'Other' },
];

const PackageCard: React.FC<PackageCardProps> = ({
    enableCategoriesSelection = false,
    color,
    length,
    indexMap,
    name,
    description,
    price_month,
    price_year,
    id,
    points_month,
    points_year
}) => {

    const [index, setIndex] = useState<number>(0)
    const { loggedIn } = useAuthContext()
    const navigation = useNavigation()
    const { t } = useTranslation()

    const [isChecked, setChecked] = useState(false)
    const [isCheckedFootball, setCheckedFootball] = useState<boolean>(true)
    const [isCheckedBasketball, setCheckedBasketball] = useState<boolean>(false)
    const [isCheckedOtherSports, setCheckedOtherSports] = useState<boolean>(false)
    const [sportCheckboxName, setSportCheckboxName] = useState<string>('')
    const [sportId, setSportId] = useState<number | null>(null)

    const handleCheckboxChange = (checkboxName: string) => {
        setCheckedFootball(false)
        setCheckedBasketball(false)
        setCheckedOtherSports(false)

        switch (checkboxName) {
            case 'Football':
                setSportId(REACT_APP_FOOTBALL_ID)
                setCheckedFootball(true)
                break;
            case 'Basketball':
                setSportId(REACT_APP_BASKETBALL_ID)
                setCheckedBasketball(true)
                break;
            case 'Other':
                setSportId(REACT_APP_OTHERSPORTS_ID)
                setCheckedOtherSports(true)
                break;
            default:
                setSportId(null)
                setCheckedFootball(false)
                setCheckedBasketball(false)
                setCheckedOtherSports(false)
        }
    }


    return (

        <LinearGradient
            //colors={indexMap > 0 && indexMap < length - 1 ? ['#F15A24BF', '#00000071'] : ['#000000', '#000000']}
            colors={color ? ['#F15A24BF', '#00000071'] : ['#000000', '#000000']}
            //style={[styles.container, { height: !isLarge ? '100%' : indexMap > 0 && indexMap < length - 1 ? 520 : 432 }]}
            style={[styles.container, { height: !isLarge ? '100%' : 660 }]}
        >
            <TextWhite style={{ fontSize: isLarge ? typography.h2 : typography.h3, fontWeight: 'bold' }}> {name}</TextWhite>

            <View
                style={{
                    position: "absolute",
                    backgroundColor: colors.orange,
                    height: 2,
                    width: isLarge ? width * 0.04 : 40,
                    top: isWeb && isLarge ? 113 : isWeb && !isLarge ? 102 : '22%', left: '3%'
                }} />
            <View
                style={{
                    position: "absolute",
                    backgroundColor: colors.orange,
                    height: 2,
                    width: isLarge ? width * 0.04 : 40,
                    top: isWeb && isLarge ? 113 : isWeb && !isLarge ? 102 : '22%', right: '3%'
                }} />

            <Tab
                value={index}
                onChange={(e) => setIndex(e)}
                indicatorStyle={{
                    backgroundColor: 'transparent',
                }}
                variant='default'
                style={{ width: isLarge ? 140 : 220, backgroundColor: colors.activeBG, borderRadius: 100, marginVertical: 20, padding: 0 }}
                containerStyle={{ width: 130, padding: 0 }}
                titleStyle={{ color: 'white', padding: 0 }}
            >

                <Tab.Item
                    title={t("packages.monthly")}
                    titleStyle={{ fontSize: 12, color: 'white', padding: 0 }}
                    buttonStyle={[styles.buttonStyle, { backgroundColor: index === 0 ? colors.orange : 'transparent' }]}
                    containerStyle={{ padding: 0 }}
                    style={{ padding: 0 }}

                />
                <Tab.Item
                    title={t("packages.yearly")}
                    titleStyle={{ fontSize: 12, color: 'white', padding: 0 }}
                    buttonStyle={[styles.buttonStyle, { backgroundColor: index === 1 ? colors.orange : 'transparent' }]}
                    containerStyle={{ padding: 0 }}
                    style={{ padding: 0 }}

                />
            </Tab>

            <TabView containerStyle={{ width: '100%', height: '100%', overflow: 'hidden' }} value={index} onChange={setIndex} animationType="spring">

                <TabView.Item style={styles.tabViewItem} >
                    <View style={{ justifyContent: 'space-between', height: '100%' }}>

                        {isLarge ?
                            <ScrollViewIndicator

                                shouldIndicatorHide={false}
                                flexibleIndicator={false}
                                scrollIndicatorStyle={{ backgroundColor: colors.orange }}
                                scrollIndicatorContainerStyle={{ backgroundColor: colors.bg_secondary }}
                            >
                                <HTMLView

                                    value={description + ''}
                                    stylesheet={styleHtml}
                                />
                            </ScrollViewIndicator>
                            : <HTMLView

                                value={description + ''}
                                stylesheet={styleHtml}
                            />}


                        {enableCategoriesSelection &&
                            <View >

                                <Dropdown
                                    setSportCheckboxName={setSportCheckboxName}
                                    onClick={handleCheckboxChange}
                                    options={optionsDropdown}
                                />

                            </View>
                        }

                        <View style={{ alignItems: 'center' }}>

                            <Text style={styles.price}> {price_month} €</Text>
                            <Button
                                onPress={loggedIn ?
                                    () => navigation.navigate('Payment', {
                                        price: price_month,
                                        productId: sportId ? sportId : id,
                                        name: name,
                                        monthly: true,
                                        sportName: sportCheckboxName
                                    })
                                    :
                                    () => navigation.navigate('Register', {
                                        price: price_month,
                                        productId: sportId ? sportId : id,
                                        name: name,
                                        monthly: true,
                                        sportName: sportCheckboxName
                                    })
                                }
                                buttonStyle={styles.tabViewButton}
                                titleStyle={styles.tabViewButtonTitle}
                                title={t("packages.buy")}
                                disabled={enableCategoriesSelection && !sportId}
                            />

                            {loggedIn && <Button
                                onPress={
                                    () => navigation.navigate('Payment', {
                                        price: points_month,
                                        productId: sportId ? sportId : id,
                                        name: name,
                                        paymentType: 'points',
                                        monthly: true,
                                        sportName: sportCheckboxName
                                    })

                                }
                                type="outline"
                                buttonStyle={styles.buttonPoints}
                                titleStyle={{ color: colors.orange }}
                                title={`${t("packages.points")} - ${points_month}`}
                                disabled={enableCategoriesSelection && !sportId}
                            />}

                        </View>
                    </View>
                </TabView.Item>

                <TabView.Item style={styles.tabViewItem} >
                    <View style={{ justifyContent: 'space-between', height: '100%' }}>



                        {isLarge ?
                            <ScrollViewIndicator

                                shouldIndicatorHide={false}
                                flexibleIndicator={false}
                                scrollIndicatorStyle={{ backgroundColor: colors.orange }}
                                scrollIndicatorContainerStyle={{ backgroundColor: colors.bg_secondary }}
                            >
                                <HTMLView

                                    value={description + ''}
                                    stylesheet={styleHtml}
                                />
                            </ScrollViewIndicator>
                            : <HTMLView

                                value={description + ''}
                                stylesheet={styleHtml}
                            />}

                        {enableCategoriesSelection &&
                            <View>
                                <Dropdown
                                    setSportCheckboxName={setSportCheckboxName}
                                    onClick={handleCheckboxChange}
                                    options={optionsDropdown}
                                />
                            </View>
                        }
                        <View style={{ alignItems: 'center' }}>

                            <Text style={styles.price}> {price_year} €</Text>
                            <Button
                                onPress={loggedIn ?
                                    () => navigation.navigate('Payment', {
                                        price: price_year,
                                        productId: sportId ? sportId : id,
                                        name: name,
                                        monthly: false,
                                        sportName: sportCheckboxName
                                    })
                                    :
                                    () => navigation.navigate('Register', {
                                        price: price_year,
                                        productId: sportId ? sportId : id,
                                        name: name,
                                        monthly: false,
                                        sportName: sportCheckboxName
                                    })
                                }
                                disabled={enableCategoriesSelection && !sportId}
                                buttonStyle={styles.tabViewButton}
                                titleStyle={styles.tabViewButtonTitle}
                                title={t("packages.buy")}
                            />

                            {loggedIn && <Button
                                onPress={loggedIn ?
                                    () => navigation.navigate('Payment', {
                                        price: points_year,
                                        productId: sportId ? sportId : id,
                                        name: name,
                                        paymentType: 'points',
                                        monthly: false,
                                        sportName: sportCheckboxName
                                    })
                                    :
                                    () => navigation.navigate('Register', {
                                        price: points_year,
                                        productId: sportId ? sportId : id,
                                        name: name,
                                        paymentType: 'points',
                                        monthly: false,
                                        sportName: sportCheckboxName
                                    })
                                }
                                disabled={enableCategoriesSelection && !sportId}
                                type="outline"
                                buttonStyle={styles.buttonPoints}
                                titleStyle={{ color: colors.orange }}
                                title={`${t("packages.points")} - ${points_year}`}
                            />}

                        </View>
                    </View>
                </TabView.Item>

            </TabView>

        </LinearGradient >
    )
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'black',
        borderRadius: 20,
        borderWidth: 2,
        borderColor: colors.orange,
        paddingVertical: 15,
        alignItems: "center",
        justifyContent: "center",
        position: 'relative',
        //maxWidth: 392,
        minWidth: width > 1980 ? 400 : 340,
        minHeight: !isLarge && 680
    },
    buttonStyle: {
        margin: 2, borderRadius: 30, padding: 0
    },
    tabViewItem: {
        padding: 10, width: '100%', flex: 1, flexDirection: "column", flexShrink: 1
    },
    tabViewDescription: { color: 'white', fontSize: typography.p, fontStyle: 'italic', fontWeight: '300' },
    price: { color: 'white', fontSize: typography.price, fontWeight: '700', },
    tabViewButton: { width: 192, backgroundColor: colors.orange, borderRadius: 5 },
    tabViewButtonTitle: { fontSize: typography.p, fontWeight: '500' },
    checkbox: {
        margin: 8,
    },
    checkboxContainer: {
        flexDirection: 'row',
        gap: 10,
        alignItems: 'center',
        marginVertical: 10
    },
    buttonPoints: {
        borderColor: colors.orange,
        color: colors.orange,
        width: 230,
        marginTop: 15,
        borderRadius: 5,
    }
})

const styleHtml = StyleSheet.create({
    p: {
        color: 'white'
    },

    div: {
        color: 'white'
    }
})

export default PackageCard