import { Icons } from "@/theme/theme"
import { isLarge } from "@/util/isLarge"
import { Image } from "react-native"

type StatusBasge = {
    status: 'Pending' | 'Win' | 'Canceled' | 'Lose' | 'Postponed'
}

const StatusBadge: React.FC<StatusBasge> = ({ status }) => {


    if (status === 'Win') {
        return (
            <Image style={{ width: 38, height: 18 }} source={Icons.win} />
        )
    }

    if (status === 'Canceled') {
        return (
            <Image style={{ width: 65, height: 18 }} source={Icons.canceled} />
        )
    }

    if (status === 'Pending') {
        return (
            <Image style={{ width: 60, height: 18, borderRadius: 6 }} source={Icons.pending} />
        )
    }

    if (status === 'Lose') {
        return (
            <Image style={{ width: 37, height: 18 }} source={Icons.lose} />
        )
    }

    if (status === 'Postponed') {
        return (
            <Image style={{ width: 72, height: 18 }} source={Icons.postponed} />
        )
    }

}

export default StatusBadge