import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CartItemType } from "./cart-actions";

interface CartState {
    items: CartItemType[]
    totalQuantity: number | string
    totalPrice: string | number
    changed: boolean
}

const initialState: CartState = {
    items: [],
    totalQuantity: 0,
    totalPrice: 0,
    changed: false,
};

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        replaceCart(state, action: PayloadAction<{ totalQuantity: number; items: CartItemType[]; totalPrice: string }>) {
            state.totalQuantity = action.payload.totalQuantity;
            state.items = action.payload.items;
            state.totalPrice = action.payload.totalPrice
        },

        addItemToCart(state, action: PayloadAction<CartItemType>) {

            const newItem = action.payload;
            const existingItem: CartItemType = state.items.find((item) => item.modelId === newItem.id);
            state.totalQuantity++;
            state.changed = true;

            if (!existingItem) {
                state.items.push({
                    id: newItem.id,
                    name: newItem.name,
                    quantity: 1,
                    price: newItem.price,
                    totalPrice: newItem.price,
                });
            } else {
                existingItem.quantity++;
                existingItem.totalPrice = (parseFloat(existingItem.price) * existingItem.quantity).toFixed(2);
            }
        },

        removeItemFromCart(state, action: PayloadAction<number>) {
            const id = action.payload

            const existingItem = state.items.find((item) => item.id === id);

            state.totalQuantity--;
            state.changed = true;
            state.totalPrice = state.totalPrice - parseFloat(existingItem?.price)
            if (existingItem?.quantity === 1) {
                state.items = state.items.filter((item) => item.id !== id);
            } else {
                existingItem!.quantity--;
                existingItem!.totalPrice = (
                    parseFloat(existingItem!.totalPrice) - parseFloat(existingItem!.price)
                ).toFixed(2);
            }
        },

        increaseItem(state, action) {

            state.totalQuantity++;
            state.items = action.payload.items
            state.totalPrice = parseFloat(action.payload.totalPrice)
        }
    },
});

export const cartAction = cartSlice.actions

export default cartSlice