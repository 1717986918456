import { ColorsType } from "./types"

export const colors: ColorsType = {
    bg_main: '#09131E',
    bg_secondary: '#FFFFFF26',
    text: '#FFFAFF',
    green: '#00ADB5',
    green_light: '#00ADB558',
    orange: '#F15A24',
    inactiveBG: '#292f35',
    activeBG: '#6f6a6adb',
    white_transparent: '#ffffff12',
    green_solid: '#064853',
    grey200: '#76899d'
}