import { StyleSheet, TextStyle } from "react-native";
import { Text } from "@rneui/themed";
import { ReactNode } from "react";

type TextWhiteProps = {
    children: ReactNode
    style?: TextStyle
}

const TextWhite: React.FC<TextWhiteProps> = ({ children, style }) => (
    <Text style={[styles.text, style]}>{children}</Text>
);

const styles = StyleSheet.create({
    text: { color: 'white' }
})

export default TextWhite

