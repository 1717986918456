import React, { useEffect, useState } from "react";
import { Dimensions, ScrollView, StyleSheet, View } from "react-native";
import { useFocusEffect } from "@react-navigation/native";

import { useAppContext } from "@/context/AppContext";
import { useAuthContext } from "@/context/AuthProvider";

import api from "@/util/api";
import CustomDrawer from "@/components/CustomDrawer";
import Footer from "@/components/Footer";
import Product from "@/components/Product";
import SEO from '@/components/SEO';
import { ProductType } from "@/theme/types";
import { isExtraLarge, isLarge } from "@/util/isLarge";
import { isWeb } from "@/util/isWeb";


const { width } = Dimensions.get('window')
const { height } = Dimensions.get('window')

const Catalog = () => {

    const { setPageId } = useAuthContext()
    const { homeData, screens, scrollRef, seoTitle, setSeoTitle, seoDescription, setSeoDescription } = useAppContext()
    const [products, setProducts] = useState<ProductType[]>()

    useFocusEffect(
        React.useCallback(() => {
            setPageId("Buy with points")

        }, [homeData])
    )

    useFocusEffect(
        React.useCallback(() => {

            setSeoTitle(homeData?.seo?.title)
            setSeoDescription(homeData?.seo?.description)

        }, [homeData])
    )

    useEffect(() => {

        const fetchData = async () => {

            try {
                const res = await api().get('products')
                if (res.status >= 200 && res.status <= 300) {
                    setProducts(res?.data?.data)
                }
            } catch (e) {
                console.log(e)
            }
        }

        fetchData()

    }, [])

    return (
        homeData && screens &&
        <>
            {seoTitle && isWeb && <SEO title={seoTitle} description={seoDescription} />}
            {isLarge && <View
                style={styles.menu_container}>
                <CustomDrawer />
            </View>}

            {products &&
                <ScrollView showsVerticalScrollIndicator={false} ref={scrollRef}>
                    <View
                        style={{
                            flex: 1,
                            marginRight: isExtraLarge ? 140 : 0,
                            flexDirection: 'row'
                        }}>
                        {isLarge &&
                            <View style={{ flex: 3 }}>

                            </View>}

                        <View style={{ flex: 7, flexDirection: 'row', gap: 10, flexWrap: 'wrap', justifyContent: isLarge ? 'flex-start' : 'center', alignItems: 'center' }}>
                            {products.map((product, ind) => (
                                <Product key={ind} product={product} />
                            ))}
                        </View>
                    </View>
                    <Footer />
                </ScrollView>}
        </>
    )

}


const styles = StyleSheet.create({
    menu_container: {
        flex: 1,
        minHeight: 400,
        width: width * 0.25,
        zIndex: 1,
        position: 'absolute'
    }
})


export default Catalog