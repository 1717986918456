import { uiActions } from "./ui-slice";
import { cartAction } from "./cart-slice";
import api from "@/util/api";


export type CartItemType = {
    id: number
    image?: string
    modelId?: 1
    name: string
    price: string
    quantity?: number
    totalPrice?: number
}

type CartType = {
    items: CartItemType[]
    couponId: null | string;
    discount: string;
    discountPercentage: string;
    netTotal: string;
    payable: string;
    roundOff: string;
    shippingCharges: string;
    subtotal: string;
    tax: string;
    total: string;
}

const getTotalQuantity = (items: CartItemType[]) => {
    return items.map(item => item.quantity).reduce((acc, curr) => acc + curr, 0)
}

let initial = false

export const fetchCartData = () => {

    return async (dispatch: (arg0: { payload: any; type: "cart/replaceCart" | "UI/showNotification"; }) => void) => {

        const fetchData = async () => {

            const response = await api().get('cart')

            if (response.status !== 200) {
                throw new Error('ERRRORRR')
            }
            const data = response?.data?.data
            return data
        }

        try {

            const cartData: CartType = await fetchData()

            dispatch(cartAction.replaceCart({
                items: cartData.items || [],
                totalPrice: parseFloat(cartData.total),
                totalQuantity: getTotalQuantity(cartData?.items)
            }))

        } catch (e: unknown) {

            if (!initial) {
                initial = true
                return
            }
            dispatch(uiActions.showNotification({
                status: 'error',
                title: 'Error!',
                message: e.errors[0],
                visible: true
            }))

        }

    }

}

export const sendCartData = (cart) => {

    return async (dispatch: (arg0: { payload: any; type: "UI/showNotification"; }) => void) => {


        dispatch(uiActions.showNotification({
            status: 'pending',
            title: 'Sending...',
            message: 'Sending cart data !',
            visible: true
        }))

        const sendRequest = async () => {
            const response = await api().post('cart/add', {
                productID: 1
            })

            if (!response?.ok) {
                throw new Error('ERROR sending cart data!')
            }
        }

        try {

            await sendRequest()
            dispatch(uiActions.showNotification({
                status: 'success',
                title: 'Success!!!',
                message: 'Sent cart data successfully!',
                visible: true
            }))
        } catch (e) {

            dispatch(uiActions.showNotification({
                status: 'error',
                title: 'Error!',
                message: 'Error senting data!',
                visible: false
            }))
        }

    }
}

export const increaseItemQuantity = (productIndex: number, id: number) => {
    return async (dispatch: (arg0: { payload: any; type: "UI/showNotification" | "cart/increaseItem"; }) => void) => {

        dispatch(uiActions.showNotification({
            status: 'pending',
            title: 'Sending...',
            message: 'Sending cart data !',
            visible: true
        }))

        const sendRequest = async () => {
            const response = await api().post('cart/increment', {
                productIndex: productIndex
            })
            return response


        }

        try {

            const cardData = await sendRequest()
            dispatch(uiActions.showNotification({
                status: 'success',
                title: 'Success!!!',
                message: 'Increased item successfully!',
                visible: true
            }))
            console.log('adsadasd', cardData?.data?.data);


            dispatch(cartAction.increaseItem({
                items: cardData?.data?.data?.items || [],
                totalQuantity: getTotalQuantity(cardData?.data?.data?.items),
                totalPrice: cardData?.data?.data?.total
            }))

        } catch (e) {

            dispatch(uiActions.showNotification({
                status: 'error',
                title: 'Error!',
                message: 'Error senting data!',
                visible: true
            }))
        }
    }
}

export const decreaseItemQuantity = (productIndex: number, id: number) => {
    return async (dispatch: (arg0: { payload: any; type: "UI/showNotification"; }) => void) => {

        dispatch(uiActions.showNotification({
            status: 'pending',
            title: 'Sending...',
            message: 'Sending cart data !',
            visible: true
        }))

        const sendRequest = async () => {
            const response = await api().post('cart/decrement', {
                productIndex: productIndex
            })

            console.log('decrease response', response);

        }

        try {

            await sendRequest()
            dispatch(uiActions.showNotification({
                status: 'success',
                title: 'Success!!!',
                message: 'Decreased item successfully!',
                visible: true
            }))
            console.log('asdasdasd');

            dispatch(cartAction.removeItemFromCart(id))



        } catch (e) {

            dispatch(uiActions.showNotification({
                status: 'error',
                title: 'Error!',
                message: 'Error senting data!',
                visible: true
            }))
        }
    }
}

export const addNewItemToCart = (id: number, name: string, price: string, navigation) => {
    return async (dispatch: (arg0: { payload: any; type: "UI/showNotification" | "cart/addItemToCart"; }) => void) => {

        dispatch(uiActions.showNotification({
            status: 'pending',
            title: 'Sending...',
            message: 'Sending cart data !',
            visible: true

        }))

        const sendRequest = async () => {
            const response = await api().post('cart/add', {
                productID: id
            })

            console.log('add item response', response);
            return response.data.data

        }

        try {

            const cartData = await sendRequest()
            dispatch(uiActions.showNotification({
                status: 'success',
                title: 'Success!!!',
                message: 'Added new item succesfully!',
                visible: true

            }))



            dispatch(cartAction.replaceCart({
                items: cartData.items || [],
                totalPrice: parseFloat(cartData.total),
                totalQuantity: getTotalQuantity(cartData?.items)
            }))

            navigation.navigate('Checkout')

        } catch (e) {

            dispatch(uiActions.showNotification({
                status: 'error',
                title: 'Error!',
                message: 'Error senting data!',
                visible: true

            }))
        }
    }
}

export const getCheckoutItems = () => {

    return async (dispatch: (arg0: { payload: any; type: "cart/replaceCart" | "UI/showNotification"; }) => void) => {

        const fetchData = async () => {

            const response = await api().get('checkout')

            if (response.status !== 200) {
                throw new Error('ERRRORRR')
            }
            const data = response?.data?.data
            return data
        }

        try {

            const cartData: CartType = await fetchData()

            dispatch(cartAction.replaceCart({
                items: cartData.items || [],
                totalPrice: parseFloat(cartData.total),
                totalQuantity: getTotalQuantity(cartData?.items)
            }))

        } catch (e) {

            dispatch(uiActions.showNotification({
                status: 'error',
                title: 'Error!',
                message: 'Fetching failed!',
                visible: true
            }))

        }

    }


}

export const clearCart = () => {

    return async (dispatch: (arg0: { payload: any; type: "cart/replaceCart" | "UI/showNotification"; }) => void) => {

        const fetchData = async () => {

            const response = await api().post('cart/clear')

            if (response.status !== 200) {
                throw new Error('ERRRORRR')
            }
            const data = response?.data?.data
            return data
        }

        try {

            const cartData: CartType = await fetchData()

            dispatch(cartAction.replaceCart({
                items: [],
                totalPrice: 0,
                totalQuantity: 0
            }))

        } catch (e) {

            dispatch(uiActions.showNotification({
                status: 'error',
                title: 'Error!',
                message: 'Fetching failed!',
                visible: true
            }))

        }

    }


}