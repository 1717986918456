import React, { FC, useEffect, useMemo, useState } from 'react'
import { SafeAreaView, StyleSheet, Text, View, Animated as RNAnimated, ScrollView, ActivityIndicator } from 'react-native'
import { Button } from '@rneui/themed'
import Animated, {
    useAnimatedStyle,
    useSharedValue,
    withTiming,
    Easing,
    runOnJS,
} from 'react-native-reanimated'
import Svg, { G, Path, TSpan, Text as SvgText } from 'react-native-svg'
import * as d3shape from 'd3-shape'

import background from '@/assets/confetti-min.png'
import { isLarge } from '@/util/isLarge'
import { colors, colors as themecolors } from '@/theme/colors'
import { typography } from '@/theme/typography'
import Footer from './Footer'
import api from '@/util/api'
import { PointsType } from '@/theme/types'
import ScrollViewIndicator from 'react-native-scroll-indicator';
import { useAuthContext } from '@/context/AuthProvider'

const numberOfSegments = 19
const wheelSize = isLarge ? 450 : 350
const oneTurn = 360
const angleBySegment = oneTurn / numberOfSegments
const angleOffset = angleBySegment / 2
const sliceNames = ['X1000', 'X2', 'Try Again', 'X20', 'X2', 'Try Again', 'X20', 'X50', 'X2', 'X20', 'Try Again', 'X2', 'X20', 'X2', 'X20', 'X2', 'X20', 'Try Again', 'X50']
const winningIndicesArray = [1, 4, 8, 11, 13, 15]

const Wheel = () => {

    const radius = wheelSize / 2
    const colors = ['#f15a24', '#eff0d1']
    const colorsText = ['white', 'black']
    const createPieChart = d3shape.pie().sort(null).value(() => 1)
    const pieData = createPieChart(new Array(numberOfSegments))
    const arc = d3shape.arc().outerRadius(radius).innerRadius(20)

    return (
        <View>
            <Svg width={wheelSize} height={wheelSize}>
                <G transform={`translate(${radius}, ${radius})`}>
                    {pieData.map((slice: { startAngle: any; endAngle: any }, index: number) => {
                        const [x, y] = arc.centroid(slice);
                        const rotation = `rotate(${(slice.startAngle + slice.endAngle) * (90 / Math.PI) + 93} ${x} ${y})`

                        return (
                            <G key={index} >
                                <Path
                                    d={arc(slice)}
                                    fill={index === 0 ? themecolors.bg_main : colors[index % colors.length]}
                                    stroke="transparent"
                                    strokeWidth={2}
                                />
                                <SvgText
                                    x={x - 10}
                                    y={y + 5}
                                    textAnchor="middle"
                                    fontSize={20}
                                    fontWeight={700}
                                    fontFamily="Segoe UI"
                                    fill={index === 0 ? '#bd8e37' : colorsText[index % colors.length]}
                                    transform={rotation}
                                >
                                    {sliceNames[index]}
                                </SvgText>
                            </G>
                        );
                    })}
                </G>
            </Svg>
        </View>
    )
}

const Info: FC<{ currentColor: string | number }> = ({
    currentColor,
}) => {
    return (
        <View style={styles.infoBox}>
            <Text style={styles.text}>{currentColor}</Text>
        </View>
    )
}

const Wheel4 = () => {

    const [points, setPoints] = useState<number>(0)
    const { user, setUser } = useAuthContext()


    useEffect(() => {

        const fetchData = async () => {

            try {
                const res = await api().get('/wheel')
                if (res.status >= 200 && res.status <= 300) {

                    setPoints(res?.data?.data?.points)
                }
            } catch (e) {
                console.log(e)
            }
        }

        fetchData()

    }, [])
    
    const rotation = useSharedValue(0)
    const [currentAngle, setCurrentAngle] = useState(rotation.value)
    const [spinning, setSpinning] = useState(false)
    const [winner, setWinner] = useState<number | string>()

    let angle = new RNAnimated.Value(0)

    const animatedStyles = useAnimatedStyle(() => {
        return {
            transform: [{ rotateZ: `${rotation.value}deg` }],
        }
    })

    const canSpin = useMemo(() => {
        return user.points >= points
    }, [user, points])

    const handleAngle = (value: number) => {
        setCurrentAngle(parseInt(value.toFixed(), 10))
    }

    const renderKnob = () => {
        const knobSize = 30;

        // [0, numberOfSegments]
        const YOLO = RNAnimated.modulo(
            RNAnimated.divide(
                RNAnimated.modulo(RNAnimated.subtract(angle, angleOffset), oneTurn),
                new RNAnimated.Value(angleBySegment)
            ),
            1
        );

        return (
            <RNAnimated.View
                style={{
                    width: knobSize,
                    height: knobSize * 2,
                    justifyContent: 'flex-end',
                    zIndex: 1,
                    transform: [
                        {
                            rotate: YOLO.interpolate({
                                inputRange: [-1, -0.5, -0.0001, 0.0001, 0.5, 1],
                                outputRange: ['0deg', '0deg', '35deg', '-35deg', '0deg', '0deg']
                            })
                        }
                    ]
                }}
            >
                <Svg
                    width={knobSize}
                    height={(knobSize * 100) / 57}
                    viewBox={`0 0 57 100`}
                    style={{ transform: [{ translateY: 8 }] }}
                >
                    <Path
                        d="M28.034,0C12.552,0,0,12.552,0,28.034S28.034,100,28.034,100s28.034-56.483,28.034-71.966S43.517,0,28.034,0z   M28.034,40.477c-6.871,0-12.442-5.572-12.442-12.442c0-6.872,5.571-12.442,12.442-12.442c6.872,0,12.442,5.57,12.442,12.442  C40.477,34.905,34.906,40.477,28.034,40.477z"
                        fill='#eff0d1'
                    />
                </Svg>
            </RNAnimated.View>
        );
    }

    const startSpin = async () => {
        try {
            if (!spinning) {
                setSpinning(true);
                const existingUser = user
                const res = await api().post('/wheel');

                if (res.status === 200) {

                    resetWheel()
                    setUser({ ...existingUser, points: existingUser.points - points })
                    existingUser.points = existingUser.points - points
                    angle.setValue(10);
                    const totalSpins = 5;
                    const spinDuration = 1000;
                    let currentRotation = rotation.value;

                    for (let spinCount = 0; spinCount < totalSpins; spinCount++) {
                        const randomRotation = getRandomNumber(720, 1080);
                        currentRotation += randomRotation;

                        rotation.value = withTiming(currentRotation, {
                            duration: spinDuration,
                            easing: Easing.bezier(0.23, 1, 0.32, 1),
                            useNativeDriver: true,
                        });
                    }

                    const winningIndex = res?.data?.data?.winner;
                    setWinner('Loading...');

                    const pieAngle = 360 / 19;
                    const remainingRotation = - (360 * 5 + pieAngle * winningIndex + 10);

                    rotation.value = withTiming(rotation.value + remainingRotation, {
                        duration: 3000,
                        easing: Easing.bezier(0.23, 1, 0.32, 1),
                        useNativeDriver: true,
                    }, () => {
                        setWinner(sliceNames[winningIndex]);
                        setSpinning(false);
                        handleAngle(rotation.value % 360);

                        if (winningIndicesArray.includes(winningIndex)) {
                            setUser({ ...existingUser, points: existingUser.points + 2 * points })
                        }
                    })
                }
            }
        } catch (e) {
            console.log(e)
        }
    }

    const getRandomNumber = (min: number, max: number) => {
        return Math.random() * (max - min) + min
    }

    const resetWheel = () => {
        rotation.value = withTiming(0, { duration: 0, useNativeDriver: true })
        setCurrentAngle(0)
    }

    return (
        points ?
            <ScrollViewIndicator
                shouldIndicatorHide={false}
                flexibleIndicator={false}
                scrollIndicatorStyle={{ backgroundColor: colors.orange }}
                scrollIndicatorContainerStyle={{ backgroundColor: colors.bg_secondary }}
            >
                <SafeAreaView style={styles.container}>
                    <View style={styles.containerWheel}>
                        <Text style={{ fontSize: isLarge ? typography.h2 : typography.h4, color: themecolors.text, fontWeight: 700, marginBottom: 30 }}>Τροχός της Tύχης</Text>
                        <View style={styles.circleContainer}>
                            {renderKnob()}
                            <Animated.View style={[styles.circle, animatedStyles]}>
                                <Wheel />
                            </Animated.View>
                        </View>
                        {winner && <Info currentColor={winner} />}
                        <Button
                            disabled={spinning || !canSpin}
                            onPress={startSpin}
                            title={`Spin the wheel - ${points} Points`}
                            titleStyle={{ fontSize: typography.h4 }}
                            buttonStyle={styles.button}
                            containerStyle={styles.containerbutton}
                        />

                    </View>

                    <Footer />
                </SafeAreaView>
            </ScrollViewIndicator> :
            <ActivityIndicator />
    )
}

const styles = StyleSheet.create({

    button: {
        backgroundColor: themecolors.orange,
    },
    containerbutton: {
        width: '95%',
        marginTop: 50,
        maxWidth: 450,
        borderRadius: 23,
    },
    containerWheel: {
        backgroundColor: themecolors.white_transparent,
        width: '95%',
        maxWidth: 700,
        alignItems: 'center',
        paddingVertical: 30,
        borderRadius: 15
    },
    text: {
        color: 'white',
        fontSize: 20,
    },
    infoBox: {
        marginTop: 15,
        height: 40,
        position: 'absolute',
        top: '50%',
        backgroundColor: themecolors.bg_main,
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 20,
        borderRadius: 20,
        fontSize: typography.h2

    },
    circleRow: { width: '100%', height: '50%', flexDirection: 'row' },
    pizza: { width: '50%', height: '100%' },
    pizzaRed: { backgroundColor: '#ce4257' },
    pizzaBlue: { backgroundColor: '#4361ee' },
    pizzaYellow: { backgroundColor: '#fee440' },
    pizzaGreen: { backgroundColor: '#06d6a0' },
    circle: {
        width: wheelSize,
        height: wheelSize,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 1000,
        borderWidth: 2,
        overflow: 'hidden',
        borderColor: '#ced4da',
    },
    ball: {
        width: 100,
        height: 100,
        borderRadius: 100,
        backgroundColor: 'blue',
        alignSelf: 'center'

    },
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        padding: 4
    },
    circleContainer: {
        width: wheelSize,
        height: wheelSize,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',

    },
    pointer: {
        width: 10,
        height: 30,
        backgroundColor: 'black',
        position: 'absolute',
        top: -15,
        borderWidth: 2,
        borderColor: 'white',
        zIndex: 6000,
    },
    pizzaSlice: {
        width: '50%',
        height: '50%',
        position: 'absolute',
        backgroundColor: 'transparent',
        borderTopLeftRadius: 150,
        borderTopRightRadius: 150,
        overflow: 'hidden',
    },

})

export default Wheel4