import api from "@/util/api"
import { useEffect, useState } from "react"
import { View, StyleSheet, ActivityIndicator } from "react-native"
import TextWhite from "../TextWhite";
import { colors } from "@/theme/colors";
import { Button, Switch, Overlay } from '@rneui/themed';
import { typography } from "@/theme/typography";
import { useTranslation } from "react-i18next";
import useSnackbar from "@/hooks/useSnackbar";
import { useDispatch } from "react-redux";
import { uiActions } from "@/store/ui-slice";

type AccountPackage = {
    created_at: string;
    description: string;
    ends_at: string | null;
    name: string;
    price_month: number;
    price_year: number;
    points_month: number;
    points_year: number;
    product_id: string;
    payment_type: string;
    subscription_id: string;
    subscription_status: string;
    updated_at: string;
    active: string;
    renew_date: string;
};

type SwitchesType = {
    payment_type: any;
    id: string;
    isChecked: boolean;
    name: string;
    price_month: number;
    points_month: number;
    active: string;
    renew_date: string
}

const MyPackages = ({ SnackbarComponent, showSnackbar }) => {

    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [packages, setPackages] = useState<AccountPackage[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [switches, setSwitches] = useState<SwitchesType[]>([])

    useEffect(() => {

        const fetchData = async () => {

            try {
                const res = await api().get('/member/packages')
                if (res.status >= 200 && res.status <= 300) {
                    setPackages(res?.data?.data?.packages?.data)
                }

            } catch (e) {
                console.log(e)
            }
        }

        fetchData()

    }, [])

    useEffect(() => {

        setSwitches(packages.map((pkg) => ({
            id: pkg.subscription_id,
            isChecked: pkg.subscription_status === 'active' && pkg.ends_at === null ? true : false,
            name: pkg.name,
            price_month: pkg.price_month,
            points_month: pkg.points_month,
            payment_type: pkg.payment_type === 'points' ? true : false,
            active: pkg.subscription_status,
            renew_date: pkg.renew_date
        })))

    }, [packages])




    const handleSubmit = async (id: string, action: string, subscriptionStatus: string) => {


        if (subscriptionStatus === 'canceled' && action === 'status') {
            if (confirm('Your account will be charged. Are you sure you want continue?')) {

            } else {
                return
            }
        }

        setLoading(true)

        try {
            const res = await api().put('/package', {
                subscription_id: id,
                actionType: action
            })
            console.log('res', res);

            if (res.status >= 200 && res.status <= 300) {

                dispatch(uiActions.showNotification({
                    message: res?.data?.message,
                    visible: true
                }))

                if (action === 'status') {
                    setSwitches((prevSwitches) =>
                        prevSwitches.map((sw) =>
                            sw.id === id ? { ...sw, isChecked: !sw.isChecked } : sw
                        )
                    )
                } else {
                    setSwitches((prevSwitches) =>
                        prevSwitches.map((sw) =>
                            sw.id === id ? { ...sw, payment_type: !sw.payment_type } : sw
                        )
                    )
                }
                try {
                    const res = await api().get('/member/packages')
                    if (res.status >= 200 && res.status <= 300) {
                        setPackages(res?.data?.data?.packages?.data)
                    }

                } catch (e) {
                    console.log(e);
                }

            }

        } catch (e) {
            showSnackbar(e?.errors)
        } finally {
            setLoading(false)
        }

    }

    return (
        <View style={styles.container}>
            {/* <Button
                containerStyle={{ marginVertical: 30, alignItems: 'flex-end' }}
                buttonStyle={{ borderWidth: 1, borderColor: colors.orange, borderRadius: 10, backgroundColor: colors.orange, width: 180 }}
                titleStyle={{ fontSize: typography.normal }}
                onPress={handleSubmit}
                title={t('account.save')}
                loading={loading}
                disabled={loading}
                disabledStyle={{ backgroundColor: colors.orange }}
                loadingProp={{ color: colors.orange }}
            /> */}
            <View style={{ maxWidth: 600 }}>
                {switches?.length > 0 ? switches.map((item: SwitchesType, ind) => (
                    <>
                        <Overlay overlayStyle={{ backgroundColor: 'transparent' }} isVisible={loading}>
                            <ActivityIndicator color={colors.orange} size={'large'} />
                        </Overlay>

                        <View key={ind} style={styles.itemContainer}>

                            <TextWhite style={styles.text}>Name : {item.name}</TextWhite>

                            <TextWhite style={styles.text}>Monthly Price : {item.price_month} €</TextWhite>
                            <TextWhite style={styles.text}>Points Price : {item.points_month} </TextWhite>
                            <TextWhite style={styles.text}>Status</TextWhite>
                            <Switch
                                color={colors.orange}
                                value={item?.isChecked}
                                onValueChange={() => handleSubmit(item?.id, 'status', item?.active)}
                            />
                            <TextWhite style={styles.text}>Pay With Points </TextWhite>
                            <Switch

                                color={colors.orange}
                                value={item?.payment_type}
                                onValueChange={() => handleSubmit(item?.id, 'paymentType', item?.active)}
                            />
                            <TextWhite style={styles.text}>Renew Date : {item?.renew_date}</TextWhite>
                        </View>
                    </>
                )) :
                    <ActivityIndicator size='large' />
                }
            </View>
        </View>
    )

}

const styles = StyleSheet.create({
    container: {
        flex: 7,
        paddingHorizontal: 10,
        gap: 15,
    },
    itemContainer: {
        borderWidth: 1,
        borderRadius: 15,
        borderColor: colors.orange,
        padding: 10,
        gap: 10,
        backgroundColor: colors.white_transparent,
        marginBottom: 10
    },
    text: {
        fontSize: typography.p,
        marginTop: 15
    }
})

export default MyPackages