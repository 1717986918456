import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from 'react-redux'

import uiSlice from "./ui-slice";
import cartSlice from "./cart-slice";
import notificationsSlice from "./notifications-slice";

const store = configureStore({
    reducer: {
        ui: uiSlice.reducer,
        cart: cartSlice.reducer,
        notifications: notificationsSlice.reducer
    }
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch

export default store