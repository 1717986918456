import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface Notification {
    status?: string | null;
    title?: string | null;
    message: string | null;
    visible: boolean;
}

interface UIState {
    cartIsVisible: boolean;
    notification: Notification;
}

const initialState: UIState = {
    cartIsVisible: false,
    notification: {
        status: null,
        title: null,
        message: null,
        visible: false,
    },
};

const uiSlice = createSlice({
    name: 'UI',
    initialState,
    reducers: {
        toggle(state) {
            state.cartIsVisible = !state.cartIsVisible
        },
        showNotification(state, action: PayloadAction<Notification>) {
            state.notification = {
                status: action.payload.status,
                title: action.payload.title,
                message: action.payload.message,
                visible: action.payload.visible
            }
        },
    }
})

export const uiActions = uiSlice.actions

export default uiSlice