// CardComponent.js

import React from "react";
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
} from "react-native";

export default function Card({
  name,
  description,
  interval,
  price,
  stripePlan,
  id,
  onPress,
}) {
  // Platform-specific styles
  const styles = {
    // Common styles
    card: {
      maxWidth: 345,
      marginBottom: 16,
      ...(Platform.OS === "web"
        ? {
            cursor: "pointer",
            ":hover": {
              background: "#eee",
            },
          }
        : {}),
    },
    cardContent: {
      padding: 16,
    },
    cardActionArea: {
      flex: 1,
    },
    // React Native-specific styles
    nativeStyles: {
      touchable: {
        padding: 16,
      },
    },
  };

  return (
    <>
      {/*
        React Native Card
      */}
      {/* <TouchableOpacity
        style={[styles.card, styles.nativeStyles.touchable]}
        onPress={onPress}
      >
        <View style={styles.cardContent}>
          <Text style={{ fontSize: 20, fontWeight: "bold" }}>
            {name}
          </Text>
          <Text>{description}</Text>
          <Text>{interval}</Text>
          <Text>{price}€</Text>
        </View>
      </TouchableOpacity> */}

      {/*
        React (Web) Card
      */}
      <TouchableOpacity style={styles.card} onPress={onPress}>
        <View style={styles.cardContent}>
          <Text style={{ fontSize: 20, fontWeight: "bold" }}>
            {name}
          </Text>
          <Text>{description}</Text>
          <Text>{interval}</Text>
          <Text>{price}€</Text>
        </View>
      </TouchableOpacity>
    </>
  );
}
