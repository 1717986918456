import FieldInput from "@/components/FieldInput"
import { colors } from "@/theme/colors"
import { typography } from "@/theme/typography"
import api from "@/util/api"
import { isLarge } from "@/util/isLarge"
import Clipboard from '@react-native-clipboard/clipboard'
import { Button, Text, Badge } from "@rneui/themed"
import { Formik, FormikValues } from 'formik'
import { useTranslation } from "react-i18next"
import { ActivityIndicator, ScrollView, StyleSheet, View } from "react-native"
import * as yup from 'yup'
import TextWhite from "../TextWhite"
import { useDispatch } from "react-redux"
import { uiActions } from "@/store/ui-slice"
import { useEffect, useState } from "react"

const UpdateInformationSchema = yup.object({
    email: yup.
        string().
        required().
        email(),
    first_name: yup.
        string().
        required(),
    last_name: yup.
        string().
        required(),
    password: yup.
        string().
        //  required().
        min(6),
    password_confirmation: yup
        .string()
        .oneOf([yup.ref("password")], "Passwords must match"),
})



const MyInformation = ({ showSnackbar, SnackbarComponent }) => {

    const { t } = useTranslation()
    const [loading, setLoading] = useState<boolean>(false)
    const [user, setUser] = useState()
    const [showBadge, setShowBadge] = useState<boolean>(false)
    const dispatch = useDispatch()

    const copyToClipboard = () => {
        setShowBadge(true)
        Clipboard.setString(user?.referralCode)
    }

    useEffect(() => {

        const fetchData = async () => {
            const response = await api().get('/member/account')
            setUser(response?.data?.data)
        }

        fetchData()

    }, [])

    const submitForm = async (values: FormikValues) => {
        setLoading(true)

        try {
            const res = await api().put('/member/account', {
                email: values.email,
                first_name: values.first_name,
                last_name: values.last_name,
                password: values.password,
                password_confirmation: values.password_confirmation
            })

            if (res?.status >= 200 && res?.status <= 300) {

                dispatch(uiActions.showNotification({
                    message: 'Informations updated successfully!',
                    visible: true
                }))
            } else {
                dispatch(uiActions.showNotification({
                    message: 'Something went wrong!',
                    visible: true
                }))

            }


        } catch (e) {
            dispatch(uiActions.showNotification({
                message: e?.errors[0],
                visible: true
            }))


        } finally {
            setLoading(false)
        }

    }

    return (
        <>
            <ScrollView style={{ flex: 7, padding: 10 }}>

                {user ?
                    <View style={{ maxWidth: 600 }}>
                        <View style={{ flexDirection: 'row', gap: 20 }}>
                            <TextWhite style={{ fontSize: typography.p }}>Referral code : </TextWhite>
                            <Text onPress={copyToClipboard} selectable={true} style={{ fontSize: typography.p, color: colors.orange, fontWeight: '700' }}>{user?.referralCode}</Text>
                            {showBadge &&
                                <Badge
                                    value={'Copied!'}
                                    containerStyle={{ justifyContent: 'center', }}
                                    badgeStyle={{ height: 23, padding: 10, backgroundColor: '#212325', borderRadius: 5 }}
                                >
                                </Badge>}
                        </View>

                        <Formik
                            initialValues={{
                                email: user.email,
                                password: '',
                                password_confirmation: '',
                                first_name: user.firstName,
                                last_name: user.lastName,
                            }}
                            validationSchema={UpdateInformationSchema}
                            onSubmit={(values, action) => submitForm(values)}
                        >
                            {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
                                <>

                                    <Button
                                        containerStyle={{ marginVertical: 30, alignItems: 'flex-end' }}
                                        buttonStyle={{ borderWidth: 1, borderColor: colors.orange, borderRadius: 10, backgroundColor: colors.orange, width: 180 }}
                                        titleStyle={{ fontSize: typography.normal }}
                                        onPress={handleSubmit}
                                        title={t('account.save')}
                                        loading={loading}
                                        disabled={loading}
                                        disabledStyle={{ backgroundColor: colors.orange }}
                                        loadingProp={{ color: colors.orange }}
                                    />


                                    <View style={{ gap: 20 }}>

                                        <FieldInput
                                            mail
                                            onChangeText={handleChange('email')}
                                            onBlur={handleBlur('email')}
                                            value={values.email}
                                            placeholder="Email"
                                        />
                                        {errors.email && touched.email && <Text style={styles.errorText}>*{errors.email}</Text>}


                                        <View style={{ flexDirection: isLarge ? 'row' : 'column', gap: 20 }}>
                                            <FieldInput
                                                user
                                                onChangeText={handleChange('first_name')}
                                                onBlur={handleBlur('first_name')}
                                                value={values.first_name}
                                                placeholder={t('form.firstname')}
                                            />

                                            <FieldInput
                                                user
                                                onChangeText={handleChange('last_name')}
                                                onBlur={handleBlur('last_name')}
                                                value={values.last_name}
                                                placeholder={t('form.lastname')}
                                            />

                                        </View>
                                        {errors.first_name && touched.first_name && <Text style={styles.errorText}>*{errors.first_name}</Text>}
                                        {errors.last_name && touched.last_name && <Text style={styles.errorText}>*{errors.last_name}</Text>}

                                        <FieldInput
                                            onChangeText={handleChange('password')}
                                            onBlur={handleBlur('password')}
                                            value={values.password}
                                            placeholder={t('form.new-password')}
                                            password
                                        />
                                        {errors.password && touched.password && <Text style={styles.errorText}>*{errors.password}</Text>}

                                        <FieldInput
                                            onChangeText={handleChange('password_confirmation')}
                                            onBlur={handleBlur('password_confirmation')}
                                            value={values.password_confirmation}
                                            placeholder={t('form.new-password-confirm')}
                                            password
                                        />
                                        {errors.password_confirmation && touched.password_confirmation && <Text style={styles.errorText}>*{errors.password_confirmation}</Text>}


                                    </View>
                                </>
                            )}
                        </Formik>
                    </View>
                    : <ActivityIndicator style={{ maxWidth: 600 }} size='large' />}
            </ScrollView>
        </>
    )

}

const styles = StyleSheet.create({
    errorText: {
        color: "#8b0000", // red color
        backgroundColor: "#ffc0cb", // misty rose background color
        padding: 10,
        marginBottom: 5,
        borderRadius: 8,
    },
})

export default MyInformation