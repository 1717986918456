import { View, StyleSheet, ScrollView, Text, FlatList, Dimensions, ActivityIndicator } from 'react-native'
import TextWhite from '../TextWhite'
import { typography } from '@/theme/typography'
import { colors } from '@/theme/colors'
import { isLarge } from '@/util/isLarge';
import ScrollViewIndicator from 'react-native-scroll-indicator';
import { useAuthContext } from '@/context/AuthProvider';
import { useEffect, useState } from 'react';
import api from '@/util/api';
import { useTranslation } from 'react-i18next';

const keyExtractor = (item: { key: any }) => item.key;

const { height } = Dimensions.get('window')

type PointType = {
    created_at: string
    points: number
    reason: string
    totalPoints: number
}


const MyPoints = () => {

    const { user } = useAuthContext()
    const { t } = useTranslation()
    const [data, setData] = useState<PointType[]>()

    useEffect(() => {

        const fetchData = async () => {
            const response = await api().get('/member/points')
            setData(response?.data?.data?.pointsHistory?.data)
        }

        fetchData()

    }, [])

    return (
        <View style={{ flex: 7, padding: 10 }}>
            <View style={{ flexDirection: 'row' }}>
                <TextWhite style={{ fontSize: typography.p }}>{t('account.my-points')}:</TextWhite>
                <Text style={{ fontSize: typography.p, color: colors.orange, marginLeft: 30, fontWeight: '700' }}>{user?.points?.toLocaleString('gr-EU', { style: 'decimal' })}</Text>
            </View>

            <View>

                <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 50, marginBottom: 10, paddingHorizontal: 10 }}>
                    <TextWhite style={styles.columnHeading}> {t('table.date')}</TextWhite>
                    <TextWhite style={styles.columnHeading}> {t('table.description')}</TextWhite>
                    <TextWhite style={styles.columnHeading}> {t('table.points')}</TextWhite>
                    <TextWhite style={styles.columnHeading}> {t('table.total')}</TextWhite>
                </View>

                <ScrollViewIndicator
                    style={styles.scrollcontainer}
                    shouldIndicatorHide={false}
                    flexibleIndicator={false}
                    scrollIndicatorStyle={{ backgroundColor: colors.orange }}
                    scrollIndicatorContainerStyle={{ backgroundColor: colors.bg_secondary }}
                >
                    {data ? data.toReversed().map((item, ind) => (
                        <View key={ind} style={styles.itemContainer}>
                            <TextWhite style={styles.text}>{item.created_at}</TextWhite>
                            <TextWhite style={styles.text}>{item.reason}</TextWhite>
                            <TextWhite style={styles.text}>{item.points}</TextWhite>
                            <TextWhite style={styles.text}>{item.totalPoints}</TextWhite>
                        </View>
                    )) : <ActivityIndicator size='large' />}
                </ScrollViewIndicator>
            </View>
        </View >
    )

}

const styles = StyleSheet.create({
    scrollcontainer: { maxHeight: height * 0.7, paddingRight: isLarge ? 10 : 0  },
    columnHeading: {
        flex: 1,
        textAlign: 'center',
        fontSize: isLarge ? typography.p : 10,
        fontWeight: '700',
    },
    itemContainer: {
        flex: 4,
        backgroundColor: colors.white_transparent,
        borderRadius: 15,
        borderWidth: 1,
        borderColor: colors.orange,
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: 14,
        paddingVertical: 20,
        marginTop: 10,
        marginRight : 15,
        gap: 10,
        overflow: 'hidden'

    },
    text: {
        flex: 1,
        textAlign: 'center',
        alignSelf: 'center',
        fontSize: isLarge ? typography.p : 10
    }
})

export default MyPoints