import { Fragment, useCallback, useEffect, useState } from "react";
import {
  View,
  StyleSheet,
  Dimensions,
  Text,
  Image,

} from "react-native";
import { ParamListBase, useFocusEffect } from "@react-navigation/native";

import ScrollViewIndicator from "react-native-scroll-indicator";
import { Formik, FormikHelpers } from "formik";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Card, Divider, Button } from "@rneui/themed";
import { useSelector } from "react-redux";

import { colors } from "@/theme/colors";
import { isLarge } from "@/util/isLarge";
import {
  CartItemType,
  clearCart,
  fetchCartData
} from "@/store/cart-actions";
import api from "@/util/api";
import FieldInput from "@/components/FieldInput";
import { typography } from "@/theme/typography";
import { uiActions } from "@/store/ui-slice";
import TextWhite from "@/components/TextWhite";
import Footer from "@/components/Footer";
import { cartAction } from "@/store/cart-slice";
import * as yup from "yup";
import { } from "@/store/cart-actions";
import { useIsFocused } from "@react-navigation/native";
import { RootState } from "@/store";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { useAuthContext } from "@/context/AuthProvider";

const validationSchema = yup.object().shape({
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  address: yup.string().required("Address is required"),
  mobile: yup
    .number()
    .typeError("Mobile must be a number")
    .required("Mobile is required"),
  postal_code: yup
    .number()
    .typeError("Postal code must be a number")
    .required("Postal code is required"),
});

const { height } = Dimensions.get("window");

type CheckoutProps = {
  navigation: DrawerNavigationProp<ParamListBase>
}

const Checkout: React.FC<CheckoutProps> = ({ navigation }) => {

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const isFocused = useIsFocused();

  const { user, setUser } = useAuthContext()

  const [loading, setLoading] = useState<boolean>(false)
  const cart = useSelector((state: RootState) => state.cart.items)
  const totalPrice: number = useSelector((state: RootState) => state.cart.totalPrice)
  console.log(totalPrice);


  interface FormValues {
    first_name: string;
    last_name: string;
    address: string;
    mobile: string;
    postal_code: string;
  }

  const initialValues = {
    first_name: "",
    last_name: "",
    address: "",
    mobile: "",
    postal_code: "",
  }

  const submitForm = async (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {

    console.log(values);

    setLoading(true);
    try {
      const response = await api().post("checkout", {
        first_name: values.first_name,
        last_name: values.last_name,
        address: values.address,
        mobile: values.mobile,
        postal_code: values.postal_code,
      })

      if (response?.status >= 200 && response?.status <= 300) {
        dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Error!",
            message: response?.data?.message,
            visible: true,
          })
        )

        dispatch(
          cartAction.replaceCart({
            totalQuantity: 0,
            items: [],
            totalPrice: "0",
          })
        )
        const existingUser = user
        setUser({ ...existingUser, points: existingUser.points - totalPrice })

        action.resetForm({ values: initialValues })
        navigation.navigate("Home")
      }
    } catch (e: any) {
      console.log(e);

      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: e?.errors[0],
          visible: true,
        })
      );
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    dispatch(fetchCartData())
  }, [])

  useEffect(() => {
    if (!isFocused) {
      dispatch(clearCart())
    }
  }, [isFocused])

  return (
    <ScrollViewIndicator
      shouldIndicatorHide={false}
      flexibleIndicator={false}
      scrollIndicatorStyle={{ backgroundColor: colors.orange }}
      scrollIndicatorContainerStyle={{
        backgroundColor: colors.bg_secondary,
      }}
      style={styles.container}
    >
      {cart.length > 0 && (
        <View
        >
          <TextWhite style={styles.heading}>
            Checkout
          </TextWhite>

          <View style={styles.pageContainer}>
            {cart.map((item: CartItemType, ind: number) => (

              <View key={item.id} style={styles.itemContainer}>
                <Image

                  style={{ maxWidth: 536, aspectRatio: 1 / 1, borderRadius: 15 }}
                  resizeMode="cover"
                  source={{ uri: item.image }}
                />

                <Text style={styles.text}>{item.name}</Text>
                <Text style={styles.price}>{totalPrice}pts</Text>

              </View>
            ))}


            <Card containerStyle={styles.card}>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, action) =>
                  submitForm(values, action)
                }
              >
                {({
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  values,
                  errors,
                  touched,
                }) => (
                  <View style={{ gap: 20 }}>
                    <TextWhite style={styles.title2}> Order details</TextWhite>
                    <Divider
                      style={{ marginBottom: 20 }}
                      color={colors.orange}
                    />
                    <FieldInput
                      onChangeText={handleChange("first_name")}
                      onBlur={handleBlur("first_name")}
                      value={values.first_name}
                      placeholder="First Name"
                    />
                    {errors.first_name && touched.first_name && (
                      <Text style={styles.errorText}>
                        *{errors.first_name}
                      </Text>
                    )}
                    <FieldInput
                      onChangeText={handleChange("last_name")}
                      onBlur={handleBlur("last_name")}
                      value={values.last_name}
                      placeholder="Last Name"
                    />
                    {errors.last_name && touched.last_name && (
                      <Text style={styles.errorText}>
                        *{errors.last_name}
                      </Text>
                    )}
                    <FieldInput
                      onChangeText={handleChange("address")}
                      onBlur={handleBlur("address")}
                      value={values.address}
                      placeholder="Address"
                    />
                    {errors.address && touched.address && (
                      <Text style={styles.errorText}>
                        *{errors.address}
                      </Text>
                    )}
                    <FieldInput
                      onChangeText={handleChange("mobile")}
                      onBlur={handleBlur("mobile")}
                      value={values.mobile}
                      placeholder="Mobile"
                    />
                    {errors.mobile && touched.mobile && (
                      <Text style={styles.errorText}>
                        *{errors.mobile}
                      </Text>
                    )}
                    <FieldInput
                      onChangeText={handleChange("postal_code")}
                      onBlur={handleBlur("postal_code")}
                      value={values.postal_code}
                      placeholder="Postal Code"
                    />
                    {errors.postal_code && touched.postal_code && (
                      <Text style={styles.errorText}>
                        *{errors.postal_code}
                      </Text>
                    )}
                    {/* {errors.email && touched.email && <Text style={styles.errorText}>*{errors.email}</Text>} */}

                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button

                        containerStyle={{ width: '100%', marginVertical: 30 }}
                        style={{ alignItems: 'center' }}
                        buttonStyle={{
                          borderWidth: 1,
                          borderColor: colors.orange,
                          borderRadius: 23,
                          width: '80%',
                          minWidth: '80%',
                          backgroundColor: colors.orange,
                          alignItems: 'center'
                        }}
                        titleStyle={{ fontSize: typography.normal }}
                        onPress={handleSubmit}
                        title={t("order")}
                        loading={loading}
                        disabled={loading}
                        disabledStyle={{
                          backgroundColor: colors.orange,
                        }}
                        loadingProp={{ color: colors.orange }}
                      />
                    </View>
                  </View>
                )}
              </Formik>

            </Card>
          </View>
        </View>
      )}
      <Footer />
    </ScrollViewIndicator>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    minHeight: height * 0.8,
    paddingHorizontal: 10,
  },
  pageContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: 2,
    justifyContent: 'center'
  },
  heading: {
    fontSize: typography.h3,
    textAlign: 'center',
    marginBottom: 40
  },
  image: {
    width: 536,
    objectFit: 'contain'
  },
  card: {
    flex: 1,
    backgroundColor: colors.white_transparent,
    borderWidth: 0,
    borderRadius: 15,
    width: "100%",
    maxWidth: 536,
    paddingVertical: 20,
    minWidth: 350,
    marginTop: 0

  },
  itemContainer: {
    maxWidth: 536,
    flex: 1,
    gap: 10,
    margin: !isLarge && 15,
    backgroundColor: colors.white_transparent,
    borderRadius: 15,
    width: "100%",
    minWidth: 330,


  },
  text: {
    color: colors.text,
    fontSize: 30,
    fontWeight: 'bold',
    textAlign: 'center',
    paddingHorizontal: 4
  },
  price: {
    color: colors.orange,
    fontSize: typography.price,
    fontWeight: '700',
    textAlign: 'center',
    marginVertical: 10
  },
  italic: {
    color: "#76899d",
    fontStyle: "italic",
    fontSize: 12,
  },
  buttonsContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  errorText: {
    color: "#8b0000", // red color
    backgroundColor: "#ffc0cb", // misty rose background color
    padding: 10,
    marginBottom: 5,
    borderRadius: 8,
  },
  title2: {
    fontSize: typography.p,
    textAlign: 'center'
  }
});

export default Checkout;
