import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import en from '@/translations/en.json'
import el from '@/translations/el.json'

export const languageResources = {
    el : {translation : el} , 
    en : {translation : en}
}

i18next.use(initReactI18next).init({
    compatibilityJSON: 'v3',
    lng: 'en',
    fallbackLng: 'en' , 
    resources : languageResources
})

export default i18next