import api from "@/util/api"
import { useEffect, useState } from "react"
import TextWhite from "../TextWhite"
import ScrollViewIndicator from 'react-native-scroll-indicator';
import { View, StyleSheet, ScrollView, Text, FlatList, Dimensions, ActivityIndicator } from 'react-native'
import { useTranslation } from 'react-i18next';
import { typography } from "@/theme/typography";
import { colors } from "@/theme/colors";
import { isLarge } from "@/util/isLarge";


type UserType = {
    created_at: string
    name: string
    price: string
    type: string
}

const { height } = Dimensions.get('window')

export const formatDate = (inputDateString: string) => {
    const inputDate = new Date(inputDateString);
    const day = inputDate.getUTCDate();
    const month = inputDate.getUTCMonth() + 1; // Month is zero-based
    const year = inputDate.getUTCFullYear();

    // Add leading zeros if needed
    const formattedDay = day < 10 ? `0${day}` : `${day}`;
    const formattedMonth = month < 10 ? `0${month}` : `${month}`;

    return `${formattedDay}/${formattedMonth}/${year}`;
}

const MyPurchases = () => {

    const [data, setData] = useState<UserType[]>()
    const { t } = useTranslation()

    useEffect(() => {

        const fetchData = async () => {
            const response = await api().get('/member/purchases')
            setData(response?.data?.data?.purchases)
        }

        fetchData()

    }, [])



    return (
        <View style={{ flex: 7, padding: 10 }}>
            <View style={{ flexDirection: 'row' }}>
                <TextWhite style={{ fontSize: typography.p }}>{t('account.my-purchases')}:</TextWhite>
            </View>

            <View>

                <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 50, marginBottom: 10, paddingHorizontal: 10 }}>
                    <TextWhite style={styles.columnHeading}> {t('table.date')}</TextWhite>
                    <TextWhite style={styles.columnHeading}> {t('table.name')}</TextWhite>
                    <TextWhite style={styles.columnHeading}> {t('table.price')}</TextWhite>
                    <TextWhite style={styles.columnHeading}> {t('table.type')}</TextWhite>
                </View>

                <ScrollViewIndicator
                    style={styles.scrollcontainer}
                    shouldIndicatorHide={false}
                    flexibleIndicator={false}
                    scrollIndicatorStyle={{ backgroundColor: colors.orange }}
                    scrollIndicatorContainerStyle={{ backgroundColor: colors.bg_secondary }}
                >
                    {data ? data.toReversed().map((data, ind) => (
                        <View key={ind} style={styles.itemContainer}>
                            <TextWhite style={styles.text}>{formatDate(data.created_at)}</TextWhite>
                            <TextWhite style={styles.text}>{data.name}</TextWhite>
                            <TextWhite style={styles.text}>{data.price}</TextWhite>
                            <TextWhite style={styles.text}>{data.type}</TextWhite>
                        </View>
                    ))
                        : <ActivityIndicator size='large' />}
                </ScrollViewIndicator>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    scrollcontainer: { maxHeight: height * 0.7, paddingRight: isLarge ? 10 : 0 },
    columnHeading: {
        flex: 1,
        textAlign: 'center',
        fontSize: isLarge ? typography.p : 10,
        fontWeight: '700',
    },
    itemContainer: {
        flex: 4,
        backgroundColor: colors.white_transparent,
        borderRadius: 15,
        borderWidth: 1,
        borderColor: colors.orange,
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: 14,
        paddingVertical: 20,
        marginTop: 10,
        marginRight : 15,
        gap: 10,
        overflow: 'hidden'

    },
    text: {
        flex: 1,
        textAlign: 'center',
        alignSelf: 'center',
        fontSize: isLarge ? typography.p : 10
    }
})

export default MyPurchases