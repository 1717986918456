import api from "@/util/api";
import { uiActions } from "./ui-slice";
import { notifActions } from "./notifications-slice";


export const fetchNotifications = () => {
    let initial: boolean = true

    return async (dispatch: (arg0: { payload: any; type: "notifications/addNotifications" | "UI/showNotification"; }) => void) => {
        const fetchData = async () => {

            const res = await api().get('notifications')

            return res
            
        }
        try {
            const data = await fetchData()
            dispatch(notifActions.addNotifications({
                notifications: data?.data?.data?.data?.data[0]
            }))

        } catch (e) {
            console.log(e);
            if (initial) {
                initial = false
                return
            }
            dispatch(uiActions.showNotification({
                message: 'Error fetching Notifications!',
                visible: true
            }))
        }
    }
}



export const readAllNotifications = () => {

    return async (dispatch: (arg0: { payload: any; type: "notifications/addNotifications"; }) => void) => {

        try {
            const res = await api().put('notifications')
            dispatch(notifActions.addNotifications({
                notifications: []
            }))

        } catch (e) {
            console.log(e);

        }

    }
}

export const readOneNotification = (id: number) => {

    return async (dispatch: (arg0: { payload: { id: number }; type: "notifications/removeNotifications"; }) => void) => {
        dispatch(notifActions.removeNotifications({
            id: id
        }))

        try {
            const res = await api().put(`notification/${id}`)
            console.log('Read One Notification', res);

        } catch (e) {
            console.log('Error Notification', e)
        }

    }

}