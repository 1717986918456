import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type NotificationType = {

    data: {
        betType: {
            value: string
        }[];
        created_at: string;
        description: string;
        event: string;
        event_date: string;
        event_time: string;
        league: {
            value: string
        }[];
        odds: string;
        result: {
            value: string
        }[];
        updated_at: string;
    }[];

    id: string;

    status: {
        label: string;
        value: string;
    };
    subscriptionType: string;

}

interface NotificationsState {
    notifications: NotificationType[];
}

const initialState: NotificationsState = {
    notifications: [],
};


const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        addNotifications(state, action: PayloadAction<{ notifications: NotificationType[] }>) {
            state.notifications = action.payload.notifications
        },
        removeNotifications(state, action) {

            const id = action.payload.id
            state.notifications = state.notifications.filter((item) => item.id !== id);
        }
    }

})

export const notifActions = notificationsSlice.actions

export default notificationsSlice


