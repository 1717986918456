import React, { Fragment, useEffect, useState } from "react";
import { Button, Pressable, Text, View, Image, StyleSheet, Dimensions, ScrollView } from "react-native";

import { Badge, Divider, Icon } from "@rneui/themed";
import Modal from "react-native-modal";
import ScrollViewIndicator from 'react-native-scroll-indicator';
import { useDispatch, useSelector } from "react-redux";
import { isWeb } from "@/util/isWeb";
import { colors } from "@/theme/colors";
import { Icons } from "@/theme/theme";
import api from "@/util/api";
import { isLarge } from "@/util/isLarge";
import { readAllNotifications, readOneNotification } from "@/store/notification-actions";
import { NotificationType } from "@/store/notifications-slice";
const { height } = Dimensions.get('window')


const NotificationModal = () => {
    const dispatch = useDispatch()
    const [isModalVisible, setModalVisible] = useState<boolean>(false)
    const [isPressed, setIsPressed] = useState<boolean>(false)
    const [hoveredId, setHoveredId] = useState<number | null>(null)
    const notifications: NotificationType[] = useSelector(state => state.notifications.notifications)

    useEffect(() => {
        console.log('adasdasdasdad');
    },[])

    const handlePressIn = (id: number) => {
        setHoveredId(id)
        setIsPressed(true)
    }

    const handlePressOut = () => {
        setHoveredId(null)
        setIsPressed(false)
    }

    const handleReadAll = () => {
        dispatch(readAllNotifications())
    }

    const handleReadNotification = (id: number) => {
        dispatch(readOneNotification(id))
    }

    const toggleModal = () => {
        setModalVisible(!isModalVisible)
    }

    

    return (
        <View style={{ flex: 1 }}>
            <Pressable onPress={toggleModal}>
                <Image style={{ width: 20.75, height: 23.86, borderRadius: 100 }} source={Icons.notification} />
                {notifications.length > 0 && <Badge
                    status='error'
                    value={notifications.length}
                    containerStyle={{ position: 'absolute', top: -10, left: 10 }}
                    badgeStyle={{ borderWidth: 0 }}

                />}
            </Pressable>

            <Modal

                style={styles.modal}
                animationIn='fadeInRightBig'
                animationOut='fadeOutRightBig'
                isVisible={isModalVisible}
                onBackdropPress={() => setModalVisible(false)}
                backdropOpacity={0.2}
            >
                <ScrollViewIndicator
                    shouldIndicatorHide={false}
                    flexibleIndicator={false}
                    scrollIndicatorStyle={{ backgroundColor: colors.orange }}
                    scrollIndicatorContainerStyle={{ backgroundColor: colors.bg_secondary }}
                    style={styles.container}
                >

                    <View style={[styles.notifContainer, { alignItems: notifications.length > 0 ? 'normal' : 'center' }]}>
                        {notifications.length > 0 ?
                            <>
                                <View style={{ alignItems: 'flex-end' }}>
                                    <Pressable onPress={handleReadAll} style={styles.readAll}>
                                        <Text style={[styles.italic, { textAlign: 'center' }]}>Mark all as read</Text>
                                    </Pressable>
                                </View>
                                {notifications.toReversed().map((item, ind) => (

                                    <Fragment key={item.id}>
                                        <Pressable
                                            key={item.id}
                                            style={[
                                                styles.itemContainer,
                                                {
                                                    backgroundColor: (hoveredId === item.id) ? colors.white_transparent : 'transparent',
                                                },
                                            ]}
                                            onHoverIn={() => handlePressIn(item.id)}
                                            onHoverOut={handlePressOut}
                                            onPress={() => handleReadNotification(item.id)}
                                        >
                                            <Text style={styles.text}>{item?.data[0]?.event}</Text>
                                            <Text style={styles.textSmall}>{item?.data[0]?.betType[0]?.value} - {item?.data[0]?.result[0]?.value} </Text>

                                            <Text style={styles.italic}>Event Date : {item?.data[0]?.event_date} - {item?.data[0]?.event_time} </Text>
                                            {hoveredId === item.id && <Text style={styles.close}>Mark as read</Text>}
                                        </Pressable>
                                        {ind !== notifications.length - 1 && <Divider color={colors.orange} />}
                                    </Fragment>
                                ))}
                            </> :
                            <Text style={styles.italic}>You have no new notifications</Text>
                        }

                    </View>

                </ScrollViewIndicator>
            </Modal>
        </View>
    )
}

const styles = StyleSheet.create({
    modal: { alignItems: 'flex-end', top: 40, elevation: 5, justifyContent: 'flex-start' },
    container: { flex: 1, width: isLarge ? 500 : '100%', maxHeight: height / 1.5 },
    notifContainer: {
        backgroundColor: colors.bg_main,
        padding: 10,
        borderWidth: 1,
        borderColor: colors.orange,
        borderRadius: 10,
       // minHeight: 400,
    },
    itemContainer: {
        gap: 2,
        marginVertical: 15,
        padding: 10,
        borderRadius: 6
    },
    text: {
        color: colors.text,
        fontSize: 18
    },
    italic: {
        color: '#76899d',
        fontStyle: 'italic',
        fontSize: 12
    },
    textSmall: {
        color: '#ffffff',
        fontSize: 14
    },
    readAll: {
        borderWidth: 1,
        borderRadius: 8,
        borderColor: '#76899d',
        paddingHorizontal: 10,
        paddingVertical: 3,
        justifyContent: 'center',
        width: 130
    },
    close: {
        color: colors.white_transparent,
        fontSize: 12,
        position: 'absolute',
        top: '40%',
        right: 10,
        elevation: 10
    }
})

export default NotificationModal;
