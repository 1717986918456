import React, { useEffect, useState } from "react";
import { View } from 'react-native';

import { createDrawerNavigator } from '@react-navigation/drawer';
import { DefaultTheme, NavigationContainer } from "@react-navigation/native";
import { useDispatch } from 'react-redux';
import { Image } from "@rneui/themed";

import Overview from "@/components/Subscriptions/Overview";
import Bets from "@/screens/Bets";
import ForgotPassword from "@/screens/ForgotPassword";
import Home from "@/screens/Home";
import Login from "@auth/Login";
import Register from "@auth/Register";
import CustomDrawer from "./components/CustomDrawer";
import Header from "./components/Header";
import Wheel from "./components/Wheel4";
import { useAppContext } from "./context/AppContext";
import { useAuthContext } from "./context/AuthProvider";
import useSnackbar from "./hooks/useSnackbar";
import Account from "./screens/Account";
import CartScreen from "./screens/CartScreen";
import Catalog from "./screens/Catalog";
import Checkout from "./screens/Checkout";
import NotificationScreen from "./screens/NotificationScreen";
import Page from "./screens/Page";
import Payment from "./screens/Payment";
import SendEmail from "./screens/SendEmail";
import { fetchCartData } from "./store/cart-actions";
import { fetchNotifications } from "./store/notification-actions";
import { colors } from "./theme/colors";
import { THEME } from "./theme/theme";
import { isExtraLarge, isLarge } from "./util/isLarge";


const Drawer = createDrawerNavigator()

const MyTheme = {

  ...DefaultTheme,
  ...THEME,
  colors: {
    background: colors.bg_main,
    text: colors.text
  },
}

export default function Router() {
  const { user } = useAuthContext()
  const { screens } = useAppContext()
  const [SnackbarComponent] = useSnackbar()
  const [isLoginPage, setIsLoginPage] = useState<boolean>(false)
  const [isAccountPage, setIsAccountPage] = useState<boolean>(false)

  const dispatch = useDispatch()

  const linking = {
    enabled: true,
    prefixes: ['/'],
    config: {
      screens: {
        Home: 'home',
        Bets: 'bets/:title',
        Settings: 'settings',
        Login: 'login',
        Register: 'register',
        Account: 'account/:accountPage',
        Page: ':title',
        ForgotPassword: 'reset/password/:token',
        SendEmail: 'recover-email',
        Notifications: 'notifications',
        Wheel: 'wheel',
        Catalog: 'catalog',
        Cart: 'cart',
        Checkout: 'checkout'
      }
    },
  }

  return (
    <NavigationContainer theme={MyTheme} linking={linking}>
      {SnackbarComponent}
      <View
        style={{
          flex: 1, backgroundColor: colors.bg_main,
          //paddingHorizontal: isExtraLarge ? 140 : 10,
          paddingTop: isExtraLarge ? 30 : 10,
          height: '100%',
          flexGrow: 1
        }}
      >

        {isLarge && !isLoginPage && <Header />}

        <Drawer.Navigator
          backBehavior='order' // Back Handler
          drawerContent={props => <CustomDrawer {...props} />}
          screenOptions={{
            headerShown: !isLoginPage ? true : false,
            header: (props) => (!isLarge ? <Header {...props} /> : null),
            headerStyle: { backgroundColor: colors.bg_main, borderColor: colors.bg_main, height: 100 },
            //drawerType: isLarge && !isLoginPage && !isAccountPage ? 'permanent' : isLoginPage ? 'back' : 'slide',
            drawerType: 'back',
            drawerStyle: {
              backgroundColor: colors.bg_main,
              borderColor: colors.bg_main,
              width: isLarge ? '30%' : '70%',
              borderRadius: 15,
            },
            drawerLabelStyle: {
              color: colors.text,
              fontSize: 14,
              fontWeight: '300'
            },
            drawerItemStyle: { borderRadius: 20 },
            drawerInactiveBackgroundColor: '#7d7c7c61',
            drawerActiveBackgroundColor: '#f3e6e661',

          }}

        >

          <Drawer.Screen
            name='Home'
            component={Home}
            options={{

              drawerIcon: ({ color }) => (
                <View style={{ backgroundColor: "#09131E", paddingHorizontal: 6, paddingVertical: 9, borderRadius: 20 }}>
                  <Image style={{ width: 15, height: 10 }} source={THEME.icons.logo_menu_2x} />
                </View>
              )
            }}
          />

          <Drawer.Screen
            name='Bets'
            component={Bets}
            options={{
              drawerIcon: ({ color }) => (<Image style={{ width: 25, height: 25 }} source={{ uri: "https://reactnative.dev/img/tiny_logo.png" }} />)
            }}
          />

          <Drawer.Screen
            name='Register'
          >
            {props => <Register {...props} setIsLoginPage={setIsLoginPage} />}
          </Drawer.Screen>

          <Drawer.Screen
            name='Overview'
            component={Overview}
          />

          <Drawer.Screen
            name='Payment'
            component={Payment}
          />

          <Drawer.Screen
            name="Login"
            screen="login"
          >
            {props => <Login {...props} setIsLoginPage={setIsLoginPage} />}
          </Drawer.Screen>

          <Drawer.Screen
            name='Page'
            component={Page}
          />

          <Drawer.Screen
            name='Account'
            initialParams={{ accountPage: 'myinformation' }}
          >
            {props => <Account {...props} setIsAccountPage={setIsAccountPage} />}
          </Drawer.Screen>

          <Drawer.Screen
            name='ForgotPassword'
            component={ForgotPassword}
          />

          <Drawer.Screen
            name='SendEmail'
            component={SendEmail}
          />

          <Drawer.Screen
            name='Notifications'
            component={NotificationScreen}
          />

          <Drawer.Screen
            name='Cart'
            component={CartScreen}
          />

          <Drawer.Screen
            name='Wheel'
            component={Wheel}
          />

          <Drawer.Screen
            name='Catalog'
            component={Catalog}
          />

          <Drawer.Screen
            name='Checkout'
            component={Checkout}
          />


        </Drawer.Navigator>

      </View>

    </NavigationContainer>

  )
}
