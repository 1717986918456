// import * as React from "react";
// import Card from "@mui/material/Card";
import { useEffect, useState } from "react";
import CardComponent from "@/components/Subscriptions/Card";
import api from "@/util/api";
import SubscriptionsService from "@/services/SubscriptionsService";
export default function Oveview({ navigation, route }) {
  //   const subscriptionsData = await SubscriptionsService();
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const subscriptionsData = await SubscriptionsService();
        setData(subscriptionsData);
      } catch (error) {
        // Handle the error if needed
        console.error("Error fetching subscriptions:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <h1>SUBSCRIPTIONS</h1>
      {data.map((sub) => (
        <CardComponent
          name={sub.name}
          description={sub.description}
          interval={sub.interval}
          price={sub.price}
          stripePlan={sub.stripe_plan}
          key={sub.id}
          id={sub.id}
        />
      ))}
    </>
  );
}
