import Footer from '@/components/Footer';
import StripeCheckoutFrom from '@/components/StripeCheckoutForm';
import api from '@/util/api';
import { REACT_APP_STRIPE_KEY } from '@env';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { View, StyleSheet, ScrollView, ActivityIndicator } from 'react-native';
import { useAuthContext } from '@/context/AuthProvider';
import { RouteProp, NavigationProp } from '@react-navigation/native';
import TextWhite from '@/components/TextWhite';

type RootStackParamList = {
    Payment: {
        price: string;
        productId: string;
        name: string;
    }
};
type PaymentNavigationProp = NavigationProp<RootStackParamList, 'Payment'>;
type PaymentRouteProp = RouteProp<RootStackParamList, 'Payment'>;

const stripePromise = loadStripe(REACT_APP_STRIPE_KEY)

const Payment = ({ navigation, route }: { navigation: PaymentNavigationProp, route: PaymentRouteProp }) => {

    const [clientSecret, setClientSecret] = useState<string>()
    const [loading, setLoading] = useState<boolean>(true)
    const { registerPayload } = useAuthContext()
    const { params }: { params: { price: string; productId: string; name: string, paymentType: string, monthly: boolean, sportName: string } } = route

    useEffect(() => {

        const fetchSecret = async () => {

            try {
                const res = await api().get('/prePay')
                if (res.status >= 200 && res.status <= 300) {
                    setClientSecret(res.data.data.intent.client_secret)
                }
            } catch (e) {
                console.log('error fetch secret', e)
            } finally {
                setLoading(false)
            }

        }

        fetchSecret()

    }, [])

    const options = {
        clientSecret: clientSecret,
    }

    return (

        loading ?
            <ActivityIndicator size='large' />
            : clientSecret ?

                <ScrollView>
                    <View style={styles.container}>

                        <Elements stripe={stripePromise} options={options} >
                            <StripeCheckoutFrom
                                monthly={!registerPayload ? params?.monthly : registerPayload.monthly}
                                navigation={navigation}
                                price={!registerPayload ? params?.price : registerPayload.price}
                                productId={!registerPayload ? params?.productId : registerPayload.productId}
                                clientSecret={clientSecret}
                                name={!registerPayload ? params?.name : registerPayload.name}
                                paymentType={!registerPayload ? params?.paymentType : registerPayload.paymentType}
                                sportName={!registerPayload ? params?.sportName : registerPayload.sportName}
                            />
                        </Elements >

                    </View>
                    <Footer />
                </ScrollView> :

                <TextWhite style={{ textAlign: 'center' }}>
                    Something went wrong !
                </TextWhite>
    )

}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 10,

    }
})

export default Payment