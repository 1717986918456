// SubscriptionsService.js
import api from "@/util/api";

const fetchSubscriptions = () => {
  return api()
    .get("/subscriptions")
    .then((response) => {
      return response.data.data.subscriptions.data;
    })
    .catch((error) => {
      console.log(error);
      throw error; // Propagate the error for handling in the calling component if needed
    });
};

export default fetchSubscriptions;
