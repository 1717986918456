import { isLarge } from "@/util/isLarge";
import { Text } from "@rneui/themed"
import { View, Dimensions, StyleSheet, Button, Image } from 'react-native'
import { ImagePropCarousel } from "@/theme/types";
import { SwiperFlatList } from 'react-native-swiper-flatlist';
import { colors } from "@/theme/colors";
import { Video, ResizeMode } from 'expo-av';
import { useCallback, useEffect, useRef, useState } from "react";
import { useFocusEffect } from "@react-navigation/native";

const windowWidth = Dimensions.get("window").width;

type HomeImageProp = {
    carouselImages: ImagePropCarousel[]
    videoUrl: string
}


const HomeImage: React.FC<HomeImageProp> = ({ videoUrl, carouselImages }) => {

    const video = useRef(null)
    const [status, setStatus] = useState({})

    useEffect(() => {
        const playVideo = () => {
            video.current.playAsync()
        }

        // Trigger video playback on component mount
        playVideo()
    }, [])

    return (
        carouselImages && (
            <View style={{ width: '100%', paddingLeft: isLarge ? 40 : 0 , marginBottom: isLarge ? 140 : 30 }}>

                {/* <SwiperFlatList

                    paginationStyleItem={styles.paginationStyleItem}
                    paginationStyle={styles.paginationStyle}
                    paginationStyleItemActive={{ backgroundColor: colors.orange }}
                    index={0}
                    showPagination={carouselImages?.length > 1}
                    paginationActiveColor='black'
                    autoplay={true}
                    autoplayLoop={true}
                    style={{ borderRadius: 10 }}
                >

                    {carouselImages.map((image: ImagePropCarousel) => (
                        <Image
                            key={image.img}
                            containerStyle={{ borderRadius: 10, }}
                            source={{ uri: image.img }}
                            style={styles.image}
                            resizeMode="contain"

                        />
                    ))}

                </SwiperFlatList> */}

                <Video
                    shouldPlay={true}
                    ref={video}
                    style={styles.video}
                    source={{
                        uri: videoUrl,
                    }}
                    videoStyle={{
                        width: isLarge ? windowWidth * 0.62 : windowWidth,
                        height: isLarge ? 700 : 300
                    }}
                    useNativeControls={false}
                    resizeMode={ResizeMode.CONTAIN}
                    isLooping
                    isMuted
                    onPlaybackStatusUpdate={status => setStatus(() => status)}
                />
            </View>)

    )

}

const styles = StyleSheet.create({
    image: {
        flex: 1,
        width: isLarge ? windowWidth * 0.7 : windowWidth,
        maxHeight: isLarge ? windowWidth * 0.35 : 320,
        height: isLarge ? windowWidth * 0.35 : 320,
        aspectRatio: 1,
        borderRadius: 10,

    },
    paginationStyle: { backgroundColor: 'black', borderRadius: 13, height: 30, alignItems: 'center', paddingLeft: 5, paddingRight: 5 },
    paginationStyleItem: { width: 8, height: 8, marginHorizontal: 10 },

    container: {
        flex: 1,
        justifyContent: 'center',
        backgroundColor: '#ecf0f1',
    },
    video: {
        alignSelf: 'stretch',
        width: isLarge ? windowWidth * 0.62 : windowWidth,
        height: isLarge ? 700 : 300,
        objectFit: 'cover'
    },
    buttons: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },

});

export default HomeImage