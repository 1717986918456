import { Button, Icon } from '@rneui/themed'
import { Text, StyleSheet, View, DimensionValue } from 'react-native'
import { typography } from '@/theme/typography'
import { colors } from '@/theme/colors'
import { ReactElement } from 'react'
import { isLarge } from '@/util/isLarge'

type ButtonPressIconProps = {
    onPress: () => void
    backgroundColor?: string
    textColor?: string
    Icon?: () => ReactElement
    title: string
    borderColor?: string
    center?: boolean
    loading?: boolean
    width?: DimensionValue 
}

const ButtonPressIcon: React.FC<ButtonPressIconProps> = ({ width = 'auto', onPress, backgroundColor = colors.green_light, textColor = colors.text, Icon = null, title, borderColor = colors.green, center = false, loading = false }) => {

    const styles = StyleSheet.create({
        container: { borderRadius: 10, width: width },
        text: { color: textColor, fontSize: typography.normal },
        flex: { width: '100%', display: 'flex', flexDirection: 'row', justifyContent: center ? 'center' : 'space-between', gap: 10, borderRadius: 10, alignItems: 'center' }
    })

    return (
        <View style={styles.container} >
            <Button
                containerStyle={{ minWidth: 120 }}
                loading={loading}
                onPress={() => onPress()}
                buttonStyle={{ backgroundColor: backgroundColor, marginVertical: 10, borderRadius: 10, borderWidth: 1, borderColor: borderColor, height: 42 }}
                title={
                    <View style={styles.flex}>
                        <Text style={styles.text}>
                            {title}
                        </Text>
                        {Icon && <Icon />}
                    </View>
                }
            >
            </Button>
        </View>
    )

}



export default ButtonPressIcon