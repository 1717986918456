import { colors } from "@/theme/colors"
import { Icons } from "@/theme/theme"
import { typography } from "@/theme/typography"
import { isLarge } from "@/util/isLarge"
import { isWeb } from "@/util/isWeb"
import { Icon, Image } from "@rneui/themed"
import { useState } from "react"
import { TextInput, StyleSheet, View, NativeSyntheticEvent, TextInputFocusEventData, Text } from "react-native"


type FieldInputProps = {
    onChangeText: (e: string) => void
    onBlur: (e: NativeSyntheticEvent<TextInputFocusEventData>) => void
    value: string | number
    placeholder: string
    password?: boolean
    user?: boolean
    mail?: boolean
}

const FieldInput: React.FC<FieldInputProps> = ({ onChangeText, onBlur, value, placeholder, password = false, mail = false, user = false }) => {

    const [secureText, setSecureText] = useState<boolean>(password)

    return (
        <View style={[
            isLarge && isWeb ? { flexGrow: 1 } : !isWeb ? { flexGrow: 1 } : { flexShrink: 1 },
            { minWidth: 150 }
        ]}>

            <Text style={styles.placeholder}>{placeholder}</Text>

            <TextInput
                secureTextEntry={secureText}
                onChangeText={onChangeText}
                onBlur={onBlur}
                style={styles.textinput}
                value={value}
                placeholderTextColor={colors.orange}
            />

            {password &&

                <Image
                    style={{ width: 24, height: 24 }}
                    source={Icons.eye}
                    onPress={() => setSecureText(!secureText)}
                    containerStyle={styles.imageContainerStyle}
                />

            }

            {mail &&

                <Image
                    style={{ width: 24, height: 24 }}
                    source={Icons.envelope}
                    containerStyle={styles.imageContainerStyle}
                />

            }

            {user &&

                <Image
                    style={{ width: 24, height: 24 }}
                    source={Icons.user}
                    containerStyle={styles.imageContainerStyle}
                />

            }

        </View >

    )

}

const styles = StyleSheet.create({

    textinput: {
        backgroundColor: colors.bg_secondary,
        borderRadius: 15,
        marginVertical: 10,
        paddingHorizontal: 16,
        paddingVertical: 11,
        color: colors.orange
    },
    placeholder: {
        color: colors.text,
        marginLeft: 15,
        fontSize: typography.small
    },
    imageContainerStyle: {
        position: 'absolute',
        right: 25,
        top: isLarge ? 35 : 30
    }

})

export default FieldInput