import { fetchMenu } from '@/services/HomeService';
import React, { ReactNode, createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Platform, ScrollView, View } from 'react-native';
import { REACT_APP_API_URL } from '@env'
import * as SecureStore from "expo-secure-store";
import { HomeData, Screen } from '@/theme/types';
import api from '@/util/api';
import { useNavigation } from "@react-navigation/native"
import { fetchCartData } from '@/store/cart-actions';
const AppContext = createContext(null)
const device = Platform.OS;
import { useDispatch } from 'react-redux';
export const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

    const [screens, setScreens] = useState<Screen[]>()
    const [homeData, setHomeData] = useState<HomeData>()
    const [hideSidebar, setHideSidebar] = useState<boolean>(false)
    const [seoTitle, setSeoTitle] = useState("")
    const [seoDescription, setSeoDescription] = useState("")
    const dispatch = useDispatch()
    //scroll home page to packages refs 
    const scrollRef = useRef(null)
    const packagesRef = useRef(null)

    useEffect(() => {

        // const getMenuFromDevice = async () => {

        //     if (device === 'web') {
        //         const savedMenu = localStorage.getItem('menu')
        //         if (savedMenu) {
        //             setScreens(JSON.parse(savedMenu))

        //         }
        //     } else {
        //         const savedMenu = await SecureStore.getItemAsync('menu')
        //         if (savedMenu) {
        //             setScreens(JSON.parse(savedMenu))
        //         }
        //     }
        // }

        // getMenuFromDevice()

        const fetchData = async () => {

            const menu = await fetchMenu()

            setScreens(menu)
            if (device === 'web') {
                localStorage.setItem('menu', JSON.stringify(menu))
            } else {
                await SecureStore.setItemAsync('menu', JSON.stringify(menu))
            }
        }

        fetchData()

        const getHomeData = async () => {

            try {

                const response = await api().get('/homepage')                

                if (response.status === 200) {
                    setHomeData(response.data.data)
                }


            } catch (error) {
                console.log('Error getHomeData ', error)
            }
        }

        getHomeData()

       

    }, [])


    const nav = (screen, params = {}) => {
        const navigation = useNavigation()
        if (device == 'web') {
            // navigation.navigate(screen)
            navigation.reset({
                index: 0,
                routes: [{ name: screen }],
            })
        } else {
            navigation.navigate(screen)
        }


    }


    const contextValue = useMemo(() => {

        return {
            screens: screens, homeData: homeData, scrollRef, packagesRef, hideSidebar, setHideSidebar, seoDescription, setSeoDescription, seoTitle, setSeoTitle, nav
        }

    }, [screens, homeData, scrollRef, packagesRef, hideSidebar, seoDescription, seoTitle])

    return (
        <AppContext.Provider value={contextValue}>
            {children}
        </AppContext.Provider>
    )

}

export const useAppContext = () =>
    useContext<{
        screens: Screen[]
        homeData: HomeData
        scrollRef: React.RefObject<ScrollView>
        packagesRef: React.RefObject<View>
        hideSidebar: boolean,
        setHideSidebar: React.Dispatch<React.SetStateAction<boolean>>
        seoDescription: string,
        setSeoDescription: any,
        seoTitle: string,
        setSeoTitle: any,
        nav: any
    }>(AppContext)

export default { AppContext }

