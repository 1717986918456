import { Icons } from "@/theme/theme"
import { useNavigation } from "@react-navigation/native"
import { Avatar, Badge, Icon, Text } from "@rneui/themed"
import { Pressable, StyleSheet, View, Image } from 'react-native'
import SearchInput from "./SearchInput"
import { isExtraLarge, isLarge } from "@/util/isLarge"
import { useState } from "react"
import ButtonPressIcon from "./ButtonPressIcon"
import { colors } from "@/theme/colors"
import { useAuthContext } from "@/context/AuthProvider"
import { SafeAreaView } from "react-native-safe-area-context"
import api from "@/util/api"
import { isWeb } from "@/util/isWeb"
import * as SecureStore from "expo-secure-store";
import TextWhite from "./TextWhite"
import { typography } from "@/theme/typography"
import { useAppContext } from "@/context/AppContext";
import { useTranslation } from 'react-i18next'
import NotificationModal from "./NotificationModal"
import CartButton from "./CartButton"
import CartModal from "./CartModal"
import { useSelector } from "react-redux"
import AnimatedText from "./AnimatedText"
import { useDispatch } from "react-redux"
import { uiActions } from "@/store/ui-slice"
import { RootState } from "@/store"

const Header = () => {

    const dispatch = useDispatch()
    const { loggedIn, setLoggedIn, user, setUser } = useAuthContext()
    const [loading, setLoading] = useState<boolean>(false)
    const [search, setSearch] = useState<string>('')
    const navigation = useNavigation()
    const { homeData } = useAppContext()
    const { t } = useTranslation()
    const cartQuantity = useSelector((state: RootState) => state.cart.totalQuantity)
    const notification = useSelector((state: RootState) => state.notifications.notifications)

    const logout = async () => {

        setLoading(true)

        let res = {}

        if (isWeb) {
            res = await api().post('/logout')
        } else {
            const token = await SecureStore.getItemAsync("token");
            res = await api({ token }).delete('auth/token')
        }

        if (res.status >= 200 && res.status <= 300) {
            setLoggedIn(false)
            setLoading(false)
            dispatch(uiActions.showNotification({
                visible: true,
                message: "Logged out successfully"
            }))
            // navigation.reset({
            //     index: 0,
            //     routes: [{ name: 'Home' }],
            // })
            navigation.navigate({
                name: 'Home',
                merge: true,
            })
        }
    }

    return (

        <SafeAreaView>
            <View style={styles.container}>

                {homeData &&
                    <>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: !isLarge && '100%' }}>
                            {!isLarge && <Pressable onPress={() => navigation.toggleDrawer()}><Image source={Icons.burger_icon} style={{ width: 30, height: 30 }} /></Pressable>}
                            <Pressable
                                onPress={() => navigation.navigate({
                                    name: 'Home',
                                    merge: true,
                                })}>
                                <Image
                                    placeholderStyle={{ display: 'none' }}
                                    style={styles.logo}
                                    source={Icons.logo}
                                />
                            </Pressable>
                        </View>

                        <View style={styles.itemsContainer}>

                            {/* <SearchInput setSearch={setSearch} /> */}

                            {isLarge && <ButtonPressIcon
                                loading={loading}
                                onPress={!loggedIn ? () => navigation.navigate({
                                    name: 'Login',
                                    merge: true,

                                }) : logout}
                                title={loggedIn ? t("logout") : t("login")}
                                Icon={() => (<Image style={{ width: 13.92, height: 15.51 }} source={Icons.login} />)}
                            />}

                            {loggedIn &&
                                <>
                                    {isLarge
                                        ? <NotificationModal />
                                        : <Pressable onPress={() => navigation.navigate('Notifications')}>
                                            <Image style={{ width: 20.75, height: 23.86, borderRadius: 100 }} source={Icons.notification} />
                                            {notification.length > 0 && <Badge
                                                status='error'
                                                value={notification.length}
                                                containerStyle={{ position: 'absolute', top: -10, left: 10 }}
                                                badgeStyle={{ borderWidth: 0 }}

                                            />}
                                        </Pressable>
                                    }
                                    <View style={{ flexDirection: 'column', justifyContent: 'space-between', overflow: 'hidden' }}>
                                        <TextWhite>{t("my-points")}</TextWhite>
                                        <AnimatedText value={user?.points?.toLocaleString('gr-EU', { style: 'decimal' })} />

                                    </View>
                                    {isLarge && cartQuantity > 0
                                        ? <CartModal />
                                        : !isLarge && cartQuantity > 0 ?
                                            <Pressable onPress={() => navigation.navigate('Cart')}>
                                                <Image style={{ width: 24, height: 20, marginTop: 2 }} source={Icons.basket} />

                                                <Badge
                                                    status='error'
                                                    value={cartQuantity}
                                                    containerStyle={{ position: 'absolute', top: -10, left: 10 }}
                                                    badgeStyle={{ borderWidth: 0 }}

                                                />
                                            </Pressable> : null
                                    }
                                    <Pressable
                                        onPress={() => navigation.navigate({
                                            name: 'Account',
                                            merge: true,
                                        })}
                                    >
                                        <Avatar size={45} rounded source={Icons.avatar} />
                                        <Badge
                                            status="success"
                                            containerStyle={{ position: 'absolute', top: 5, left: 40 }}
                                        />
                                    </Pressable>
                                </>
                            }

                        </View>
                    </>}
            </View>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({

    container: {
        paddingVertical: isLarge ? 30 : 10,
        marginBottom: 20,
        flexDirection: isLarge ? 'row' : 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: isLarge ? 30 : 10,
        borderBottomWidth: 1,
        marginHorizontal: isExtraLarge ? 140 : 10,
        borderBottomColor: colors.orange,
        minHeight: 100
    },
    logo: { width: isLarge ? 200 : 100, height: isLarge ? 42 : 21 },
    itemsContainer: {
        width: !isLarge && '100%',
        flexGrow: isLarge ? 0 : 1,
        flexDirection: 'row',
        gap: 20,
        alignItems: 'center',
        justifyContent: isLarge ? null : 'flex-end'
    }

})

export default Header

