import Footer from "@/components/Footer"
import { colors } from "@/theme/colors";
import api from "@/util/api"
import { isExtraLarge } from "@/util/isLarge";
import { useEffect, useState } from "react"
import { View, StyleSheet, ScrollView, ActivityIndicator } from "react-native"
import HTMLView from 'react-native-htmlview';
import ScrollViewIndicator from 'react-native-scroll-indicator';


const Page = ({ navigation, route }) => {

    const { params } = route
    //const { title } = params
    const [content, setContent] = useState<string>()

    useEffect(() => {

        const fetchData = async () => {

            try {
                const res = await api().get(`pages/${params?.title}`)
                //console.log(res?.data?.data?.content)
                setContent(res?.data?.data?.content)
            } catch (e) {
                console.log(e)
                navigation.navigate('Home')
            }
        }

        fetchData()

    }, [])

    return (

        <ScrollViewIndicator
            style={styleHtml.container}
            shouldIndicatorHide={false}
            flexibleIndicator={false}
            scrollIndicatorStyle={{ backgroundColor: colors.orange }}
            scrollIndicatorContainerStyle={{ backgroundColor: colors.bg_secondary }}
           
        >
            {content ?
                <HTMLView
                    value={content}
                    stylesheet={styleHtml}
                />
                : <ActivityIndicator size='large' />
            }
            <Footer />
        </ScrollViewIndicator>
    )
}

const styleHtml = StyleSheet.create({
    container: {
        paddingHorizontal: isExtraLarge ? 140 : 10,
        marginTop: 20,
        height: '100%',
        justifyContent: 'space-between',
        backgroundColor: colors.bg_main
    },
    p: {
        color: 'white'
    },

    div: {
        color: 'white'
    },

    a: {
        color: colors.orange,
        fontWeight: '700'
    }
})

export default Page