import React, { useEffect, useState, useCallback } from "react";
import { StyleSheet, TextInput, View, ScrollView, Pressable } from "react-native";
import { Text, Card, Button, Input, Image } from "@rneui/themed";
import { useAuthContext } from "@/context/AuthProvider";
import { Link, useFocusEffect, } from "@react-navigation/native";

import { Formik, FormikValues } from 'formik';
import * as yup from 'yup'

import TextWhite from "@/components/TextWhite";
import { Icons } from "@/theme/theme";
import { colors } from "@/theme/colors";
import { typography } from "@/theme/typography";
import FieldInput from "@/components/FieldInput";
import { isLarge } from "@/util/isLarge";
import SEO from '@/components/SEO';
import { useTranslation } from "react-i18next";
import { useAppContext } from "@/context/AppContext";
import { isWeb } from "@/util/isWeb";
import useSnackbar from "@/hooks/useSnackbar";
import { useDispatch } from "react-redux";
import { uiActions } from "@/store/ui-slice";


const LoginSchema = yup.object({
  email: yup.
    string().
    required().
    email(),
  password: yup.
    string().
    required().
    min(4)
})

export default function Login({ navigation, route, setIsLoginPage }) {

  const { logIn, error, device, loggedIn } = useAuthContext()
  const { seoTitle, setSeoTitle, seoDescription, setSeoDescription } = useAppContext()
  const { t } = useTranslation()
  const [SnackbarComponent, showSnackbar] = useSnackbar()
  const [loading, setLoading] = useState<boolean>(false)
  const dispatch = useDispatch()

  const initialValues = {
    email: '',
    password: ''
  }

  const redirect = () => {
    showSnackbar('Logged in succesfully!')
    navigation.reset({
      index: 0,
      routes: [{ name: 'Home' }],
    })

  }

  const submitForm = async (values: FormikValues, action) => {
    setLoading(true)

    await logIn(values.email, values.password, redirect)

    action.resetForm({ values: initialValues })
    setLoading(false)

  }

  useFocusEffect(
    useCallback(() => {
      setIsLoginPage(true)

      return () => setIsLoginPage(false)
    }, [navigation])
  )

  useFocusEffect(
    React.useCallback(() => {

      setSeoTitle('Login')
      setSeoDescription('Login')

    }, [])
  );


  return (
    <ScrollView>

      <View style={styles.container}>
        {seoTitle && isWeb && <SEO title={seoTitle} description={seoDescription} />}

        <Image

          onPress={() => navigation.reset({
            index: 0,
            routes: [{ name: 'Home' }],
          })}
          style={{ width: 320, height: 70 }}
          placeholderStyle={{ width: 320, height: 70, backgroundColor: 'transparent' }}
          source={Icons.logo}
        />
        <Card containerStyle={styles.card}>

          {error && <Text style={styles.errorText}>{error}</Text>}

          <TextWhite style={styles.enter}>
            {t("login")}
          </TextWhite>

          <Formik
            initialValues={initialValues}
            validationSchema={LoginSchema}
            onSubmit={(values, action) => submitForm(values, action)}
          >
            {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
              <View style={{ gap: 20 }}>
                <FieldInput
                  onChangeText={handleChange('email')}
                  onBlur={handleBlur('email')}
                  value={values.email}
                  placeholder="email"
                />
                {errors.email && touched.email && <Text style={styles.errorText}>*{errors.email}</Text>}
                <FieldInput
                  onChangeText={handleChange('password')}
                  onBlur={handleBlur('password')}
                  value={values.password}
                  placeholder="password"
                  password
                />
                {errors.password && touched.password && <Text style={styles.errorText}>*{errors.password}</Text>}
                <View style={{ flexDirection: "row", marginVertical: 20, alignItems: 'center', justifyContent: 'space-between' }}>
                  <Pressable onPress={() => navigation.navigate('SendEmail')} >
                    <Text style={styles.register}>{t("forgot-password")}</Text>
                  </Pressable>

                  <Button

                    buttonStyle={{ borderWidth: 1, borderColor: colors.orange, borderRadius: 10, width: 120, backgroundColor: colors.orange }}
                    titleStyle={{ fontSize: typography.normal }}
                    onPress={handleSubmit}
                    title={t("login")}
                    loading={loading}
                    disabled={loading}
                    disabledStyle={{ backgroundColor: colors.orange }}
                    loadingProp={{ color: colors.orange }}
                  />
                </View>

              </View>
            )}
          </Formik>
        </Card>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    backgroundColor: 'transparent',
    width: '100%',
    height: '100%',
    gap: isLarge ? 100 : 30,
    marginTop: isLarge ? 100 : 50,
    paddingHorizontal: 10
  },
  card: {
    backgroundColor: colors.white_transparent,
    borderWidth: 0,
    borderRadius: 15,
    width: '100%',
    maxWidth: 568,
    paddingVertical: 30,

  },

  enter: {
    textAlign: "center",
    fontSize: typography.h3,
    fontWeight: 'bold'
  },

  member: {
    color: colors.text,
    fontSize: typography.p,

  },
  register: {
    fontSize: typography.normal,
    color: colors.orange,

  },
  email: {
    padding: 8,
  },
  password: {
    padding: 8,
  },

  image: {
    marginBottom: 40,
  },
  errorText: {
    color: "#8b0000"
  },
});
