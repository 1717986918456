import { useEffect } from "react"
import { Pressable, ScrollView, View, Text, StyleSheet } from "react-native"

import { useTranslation } from "react-i18next"
import { Button, Divider } from "@rneui/themed"
import { useDispatch, useSelector } from "react-redux"

import Footer from "@/components/Footer"
import { colors } from "@/theme/colors"
import { isLarge } from "@/util/isLarge"
import { typography } from "@/theme/typography"
import { readAllNotifications, readOneNotification } from "@/store/notification-actions"
import { NotificationType } from "@/store/notifications-slice"


const NotificationScreen = () => {

    const { t } = useTranslation()
    const dispatch = useDispatch()
    const notifications: NotificationType[] = useSelector(state => state.notifications.notifications)
    
    const handleReadAll = () => {
        dispatch(readAllNotifications())
    }

    const handleReadNotification = (id: number) => {
        dispatch(readOneNotification(id))
    }

    return (
        <ScrollView>
            <View style={styles.notifContainer}>
                <View >
                    <Text style={styles.title}>{t('notifications')}</Text>
                </View>

                {notifications.length > 0 ?
                    <>
                        <Pressable onPress={handleReadAll} style={styles.readAll}>
                            <Text style={[styles.italic, { textAlign: 'center' }]}>Mark all as read</Text>
                        </Pressable>
                        {notifications.map((item, ind) => (
                            <View
                                key={item.id}
                                style={styles.itemContainer}
                            >
                                <Text style={styles.text}>{item?.data[0]?.event}</Text>
                                <Text style={styles.textSmall}>{item?.data[0]?.betType[0]?.value} - {item?.data[0]?.result[0]?.value} </Text>

                                <Text style={styles.italic}>Event Date : {item?.data[0]?.event_date} - {item?.data[0]?.event_time} </Text>
                                <Button
                                    onPress={() => handleReadNotification(item.id)}
                                    type="clear"
                                    title='Mark as read'
                                    containerStyle={styles.buttonContainer}
                                    titleStyle={{ color: colors.grey200, fontSize: 10 }}
                                />
                            </View>
                        ))}
                    </> : <Text style={styles.italic}>You have no new notifications</Text>}
            </View>
            <Footer />
        </ScrollView>
    )

}

const styles = StyleSheet.create({
    modal: { alignItems: 'flex-end', top: 40, elevation: 5 },
    container: { flex: 1, width: isLarge ? 500 : '100%' },
    notifContainer: { backgroundColor: colors.bg_main, padding: 10 },
    itemContainer: {
        gap: 10,
        marginVertical: 10,
        padding: 10,
        borderRadius: 6,
        flexDirection: 'column',
        alignItems: 'flex-start',
        backgroundColor: colors.white_transparent
    },
    buttonContainer: {
        position: 'absolute',
        right: 5,
        bottom: 2
    },
    text: { color: colors.text, fontSize: typography.normal },
    title: { color: colors.text, fontSize: typography.h3, marginBottom: 10 },
    italic: { color: colors.grey200, fontStyle: 'italic', fontSize: 10 },
    readAll: {
        borderWidth: 1,
        borderRadius: 8,
        borderColor: '#76899d',
        paddingHorizontal: 10,
        paddingVertical: 3,
        justifyContent: 'center',
        width: 130
    },
    textSmall: {
        color: '#ffffff',
        fontSize: 14
    },
})

export default NotificationScreen