import React, {
  useEffect,
  useState,
  createContext,
  useContext,
  useMemo,
} from "react";
import { View, ActivityIndicator, Platform } from "react-native";
import * as SecureStore from "expo-secure-store";

import { useDispatch } from "react-redux";
import { NotificationType, notifActions } from "@/store/notifications-slice";
import { uiActions } from "@/store/ui-slice";
import api from "@/util/api";
import { RegisterPayload, User } from "@/theme/types";
import { isWeb } from "@/util/isWeb";

const AuthContext = createContext(null)

export const notifyUser = (notifications: NotificationType[]) => {

  if (isWeb) {

  }

  if (!("Notification" in window)) {
    console.log('Notifications not supported!');
  } else if (Notification.permission === "granted") {

    let notificationIds: string[] = [];

    if (isWeb) {
      notificationIds = JSON.parse(localStorage.getItem('notificationIds')) || []
    }

    notifications.map((item) => {

      if (isWeb) {
        const text = `${item.data[0].league[0].value}\n${item.data[0].event} `
        const notificationId: string = item.id;
        if (!notificationIds.includes(notificationId)) {
          notificationIds.push(notificationId);
          localStorage.setItem('notificationIds', JSON.stringify(notificationIds));
          const notification = new Notification(text)
        }
      }
    })

  } else if (Notification.permission !== "denied") {
    console.log('denied!');
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        notifications.map((item) => {
          const text = `${item.data[0].league[0].value}\n${item.data[0].event} `
          const notification = new Notification(text)
        })
      }
    });
  }


  if (isWeb) {

  }
};

export const AuthProvider = ({ children }) => {

  const [user, setUser] = useState<User>(null)
  const [error, setError] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const device = Platform.OS;
  const [loading, setLoading] = useState(true);
  const [pageId, setPageId] = useState<number | string>()
  const [registerPayload, setRegisterPayload] = useState<RegisterPayload>()
  const dispatch = useDispatch()

  const checkAuthUser = async () => {
    try {
      if (device !== "web") {

        const token = await SecureStore.getItemAsync("token");


        if (token) {

          const response = await api({ token }).put("/auth/token", {
            token,
            device,
          })

          console.log(response);
          

          if (response.data.data.loggedIn) {
            setLoggedIn(response.data.data.loggedIn)
            setUser(response.data.data.member)
            dispatch(notifActions.addNotifications({
              notifications: response?.data?.data?.member?.notifications
            }))
            setLoading(false)
          }
        }
      } else {

        await api().get(`/sanctum/csrf-cookie`)

        const response = await api().put("/checkAuth")

        if (response.data.data.loggedIn === true) {
          setLoggedIn(response.data.data.loggedIn)
          setUser(response.data.data.member)
          dispatch(notifActions.addNotifications({
            notifications: response?.data?.data?.member?.notifications
          }))
          setLoading(false)


        }

      }

    } catch (error) {
      console.log("error Auth Provider", error)
      setLoading(false)
    } finally {
      setLoading(false)
    }

  }

  useEffect(() => {
    // Call checkAuthUser when the component mounts
    checkAuthUser()
  }, [])

  const logIn = async (email: string, password: string | number, redirect?: () => void) => {
    try {
      const loginData = {
        email,
        password,
        device,
      };

      if (device === "web") {
        // Get CSRF token
        // await api().get("/sanctum/csrf-cookie");
        // console.log('logigig', loginData)

        // Perform login for web
        try {
          const response = await api().post(`/login`, loginData)
          console.log('res', response);
          handleLoginResponse(response)
          if (redirect) {
            redirect()
          }
          if (response?.status >= 200 && response?.status <= 300) {
            dispatch(uiActions.showNotification({
              visible: true,
              message: 'Logged in successfully'
            }))
          }
        } catch (e) {
          console.log(e)
          dispatch(uiActions.showNotification({
            visible: true,
            message: e?.message
          }))
        }

      } else {

        try {
          // Perform login without CSRF token for non-web environments
          const response = await api().post(`/auth/token`, loginData)
          console.log(response);
          handleLoginResponse(response)
          // Save token for non-web environments
          SecureStore.setItemAsync("token", response.data.data.token);

          if (redirect) {
            redirect()
          }
        } catch (e) {
          console.log(e)
          dispatch(uiActions.showNotification({
            visible: true,
            message: 'Unauthorized'
          }))
        }

      }
    } catch (error) {
      // Handle errors
      // console.log('login error => ', error);
      setError(error?.errors);
      return false;
    }



  };

  const handleLoginResponse = (response: any) => {
    const userResponse = {
      email: response.data.data.member.email,
      firstName: response.data.data.member.firstName,
      lastName: response.data.data.member.lastName,
      points: response.data.data.member.points
    };

    setUser(userResponse);
    setLoggedIn(true);
    setError(null);
    // console.log('logged In success')
  };

  const logOut = async () => {
    try {
      if (device === "web") {

        try {
          const response = await api().post("/logout", {
            device,
          });
          console.log(response);

          dispatch(uiActions.showNotification({
            visible: true,
            message: 'Logged out successfully'
          }))
        } catch (e) {
          console.log(e);

        }
        // await api().delete("/logout");
      } else {
        const token = await SecureStore.getItemAsync("token");
        await api({ token }).delete("/auth/token");
      }

      setUser(null);
      setLoggedIn(false);

      if (device !== "web") {
        await SecureStore.deleteItemAsync("token");
      }
    } catch (error) {
      if (error.errors) {
        setError(error.errors[0]);
      }
    }
  };

  const register = async (data) => {
    const registrationRoute =
      device === "web" ? "/register" : "/auth/token/register";

    if (device === "web") {
      await api().get("/sanctum/csrf-cookie");
    }

    api()
      .post(registrationRoute, {
        email: data.email,
        first_name: data.firstName,
        last_name: data.lastName,
        password: data.password,
        password_confirmation: data.passwordConfirmation,
        referral_code: data.referralCode,
        device: device,
      })
      .then((response) => {
        const userResponse = {
          email: response.data.data.member.email,
          firstName: response.data.data.member.firstName,
          lastName: response.data.data.member.lastName,
        }
        setUser(userResponse)
        setLoggedIn(true)
        setError(null)

        if (device !== "web") {
          SecureStore.setItemAsync("token", response.data.data.token)
          //SecureStore.setItemAsync("secure_token", process.env.react_app_TOKEN_API);

        }
      })
      .catch(({ errors }) => {
        setError(errors);
      });
  }

  const contextValue = useMemo(() => {

    return {
      user,
      loggedIn,
      setLoggedIn,
      setUser,
      error,
      setError,
      logIn,
      register,
      logOut,
      device,
      pageId,
      setPageId,
      registerPayload,
      setRegisterPayload
    }

  }, [pageId, registerPayload, user, loggedIn, error])

  useEffect(() => {

    if (user?.notifications) {
      notifyUser(user.notifications)
    }

  }, [user])

  if (loading) {
    return (
      <View
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ActivityIndicator size="large" />
      </View>
    );
  }

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () =>
  useContext<{
    device: String;
    user: User;
    loggedIn: boolean;
    setLoggedIn: any;
    error: any;
    setUser: any;
    setError: any;
    logIn: (email: string, password: string, redirect: () => void) => boolean;
    register: any;
    logOut: () => void;
    pageId: string,
    setPageId: any
    registerPayload: RegisterPayload
    setRegisterPayload: React.Dispatch<React.SetStateAction<RegisterPayload>>
  }>(AuthContext);

export default AuthContext
