import axios from "axios";
import { Platform } from "react-native";
import { REACT_APP_API_URL_MOBILE, REACT_APP_API_URL_WEB } from '@env'
// import Cookies from "js-cookie";

const api = ({ token = null } = {}) => {

  const device = Platform.OS;
  // const baseUrl =
  //   device === "android"
  //     ? "https://admin.klokbet.lncdoo.com/api/v1"
  //     : device === "web"
  //       ? "https://admin.klokbet.lncdoo.com"
  //       : "https://admin.klokbet.lncdoo.com/api/v1";

  const baseUrl =
    device === "android"
      ? REACT_APP_API_URL_MOBILE
      : device === "ios"
        ? REACT_APP_API_URL_MOBILE
        : REACT_APP_API_URL_WEB;

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "true"
  };

  const api = axios.create({
    baseURL: baseUrl,
    headers,
  });

  axios.defaults.withCredentials = true;
  axios.defaults.withXSRFToken = true;
  //api.defaults.headers.common["X-HEADER-ADDITIONAL-PROTECT"] = process.env.react_app_TOKEN_API;

  if (token) {
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    //api.defaults.headers.common["X-HEADER-ADDITIONAL-PROTECT"] = `${secureToken}`;

  }

  // Request interceptor. Runs before request reaches the server
  const onRequest = (config) => {
    // If http method is `post | put | delete` and XSRF-TOKEN cookie is
    // not present, call '/sanctum/csrf-cookie' to set CSRF token, then
    // proceed with the initial response
    if (
      config.method == "post" ||
      config.method == "put" ||
      config.method == "delete"
      /* other methods you want to add here */
      // !Cookies.get("XSRF-TOKEN")
    ) {
      return setCSRFToken().then((response) => config);
    }


    return config;
  };

  const onRequest2 = (config) => {
    // If http method is `post | put | delete` and XSRF-TOKEN cookie is
    // not present, call '/sanctum/csrf-cookie' to set CSRF token, then
    if (
      config.method == "post" ||
      config.method == "put" ||
      config.method == "delete"
      /* other methods you want to add here */
      // !Cookies.get("XSRF-TOKEN")
    ) {
      return apiProt().then((response) => config);
    }

    //console.log('config => ', config)
    return config;



  };

  // get csrf cookie
  const setCSRFToken = () => {
    return api.get("/sanctum/csrf-cookie");
  };


  // get csrf cookie
  const apiProt = () => {
    return api.get("/api-protection");
  };

  //do something to response before gets handled by then or catch
  //if response ok continue, if error run this code
  if (device === "web") {
    api.interceptors.request.use(onRequest, null);

  }
  //api.interceptors.request.use(onRequest2, null);
  api.interceptors.response.use(

    (response) => response,

    (error) => {

      console.log('Error from api tsx', error)

      if (error?.response?.status === 401) {
        //reject means to go to catch block
        return Promise.reject({
          status: 401,
          errors: ["Unauthorized"],
        })
      }

      if (error?.response?.status === 422) {
        let errors = Object.values(
          error?.response?.data?.errors || {}
        )

        return Promise.reject({
          status: 422,
          errorsRaw: errors,
          errors: errors?.reduce((error) => error),
        })
      }

      return Promise.reject({
        status: error?.response?.status,
        errors: error?.response?.data?.message,
        message: error?.response?.data?.data
      })

    }

  )

  return api

}

export default api
