import React, { ReactNode, useState } from 'react'
import { View, StyleSheet, Text } from 'react-native'
import { Button, Snackbar } from 'react-native-paper'
import { colors } from '@/theme/colors';
import { isLarge } from '@/util/isLarge';
import { Icons } from '@/theme/theme';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { uiActions } from '@/store/ui-slice';
type SnackbarProps = {
    visible: boolean;
    onDismiss: () => void;
    action?: {
        label: string;
        onPress: () => void;
    };
    children: ReactNode;
};


const useSnackbar = () => {
    const [visible, setVisible] = useState(false)
    const [snackbarText, setSnackbarText] = useState<string | ReactNode>('')
    const dispatch = useDispatch()
    const notification = useSelector(state => state.ui.notification)
    const onToggleSnackBar = () => setVisible(!visible)
    const onDismissSnackBar = () => setVisible(false)

    const showSnackbar = (text: string | ReactNode) => {
        setSnackbarText(text)
        setVisible(true)
    }

    const hideSnackbar = () => {
        dispatch(uiActions.showNotification({
            status: null,
            title: null,
            message: null,
            visible: false
        }))
    }

    const SnackbarComponent = (
        <Snackbar
            wrapperStyle={{ position: 'absolute', top: 0, width: '100%', zIndex: 1000, paddingHorizontal: 5 , alignItems : 'center'}}
            visible={notification.visible}
            duration={3000}
            onDismiss={hideSnackbar}
            action={{
                icon: Icons.close,
                onPress: hideSnackbar,
            }}
            style={{ backgroundColor: colors.green_solid, elevation: 5 }}
        >
            <Text style={{ color: colors.text, width: '100%' }}> {notification.message}</Text>
        </Snackbar>
    )

    return [SnackbarComponent, showSnackbar, hideSnackbar] as const
}

export default useSnackbar