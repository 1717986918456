import { TypographyType } from "./types";

export const typography: TypographyType = {
    small: 12,
    normal: 14,
    menu: 16,
    p: 19,
    product_title: 23,
    h4: 26,
    price: 33,
    h3: 37,
    h2: 45,
    h1: 77,

}