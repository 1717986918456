import React, { useState } from 'react';
import RNPickerSelect from 'react-native-picker-select';
import { View, Text } from 'react-native';
import { colors } from '@/theme/colors';

const Dropdown = ({ options, onClick, setSportCheckboxName }) => {
    const [selectedValue, setSelectedValue] = useState()

    const placeholder = {
        label: 'Select an option...',
        value: null,
    }

    const handleChange = (value: string) => {
        setSportCheckboxName(value)
        onClick(value)
    }

    return (
        <View>
            <RNPickerSelect
                style={{
                    inputWeb: {
                        padding: 10,
                        backgroundColor: 'black',
                        color: colors.orange,
                        borderColor: colors.orange,
                        borderRadius: 10
                    },
                }}
                placeholder={placeholder}
                items={options}
                onValueChange={(value) => handleChange(value)}
                value={selectedValue}
            />
            {selectedValue && <Text>Selected: {selectedValue}</Text>}
        </View>
    );
};

export default Dropdown;
